import React, {Component} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    UncontrolledTooltip,
    CardSubtitle,
} from "reactstrap";
import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from "react-redux";
import {get} from "lodash";
import "./redux/magazzini.reducer";
import MagazziniCreateComponent from "./components/magazzini.create.component";
import MagazziniUpdateComponent from "./components/magazzini.update.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from "lodash";
import {
    readedAll,
    requestDelete,
    deleted,
    deleteError,
} from "./redux/magazzini.actions";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import {runInThisContext} from "vm";

class MagazziniComponent extends Component {
    state = {
        data: [],
        padri: [],
        loading: false,
        showCreateModal: false,
        showUpdateModal: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {},
    };

    caricaMagazzini = async () => {
        try {
            const response = await GraphqlClient.ottieniMagazzini();
            store.dispatch(readedAll(response.ottieniMagazzini)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log("Impossibile caricare i dati", e);
        }
    };

    caricaPadri = async () => {
        try {
            this.state.padri = await GraphqlClient.ottieniMagazzini();

        } catch (e) {
            console.log("Impossibile caricare i dati", e);
        }
    }

    componentDidMount() {
        this.caricaMagazzini();
        this.caricaPadri();
    }

    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal,
        });
    };

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record,
        });
        this.caricaMagazzini();
    };

    elimina = async () => {
        try {

            this.toggleDeleteAlert(this.state.selectedRecordForDelete);

            store.dispatch(requestDelete(this.state.selectedRecordForDelete.id));

            await GraphqlClient.eliminaMagazzino({
                id: this.state.selectedRecordForDelete.ID,
            });

            store.dispatch(deleted({id: this.state.selectedRecordForDelete.ID})); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {

            alert('Impossibile eliminare');
            console.log("Impossibile eliminare il record", e);
            store.dispatch(deleteError(this.state.selectedRecordForDelete.ID));

        }
        this.caricaMagazzini();
    };

    toggleDeleteAlert = (record) =>
        this.setState({
            showDeleteAlert: !this.state.showDeleteAlert,
            selectedRecordForDelete: record,
        });

    actions = (cellInfo) => (
        <div className="actions-right">
            {get(cellInfo, "original._deleting", false) ? (
                <i className="fa fa-spin fa-spinner"/>
            ) : null}
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-pencil"/>
            </Button>{" "}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );

    render() {
        return (
            <>
                {this.state.showDeleteAlert ? (
                    <ReactBSAlert
                        warning
                        style={{display: "block"}}
                        title="Confermare l'eliminazione ?"
                        onConfirm={() => this.elimina()}
                        onCancel={() => this.toggleDeleteAlert()}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Si"
                        cancelBtnText="No"
                        showCancel
                        btnSize=""
                    >
                        Stai per eliminare in modo definitivo:{" "}
                        {_.get(this.state, "selectedRecordForDelete.descrizione", "")}
                    </ReactBSAlert>
                ) : null}
                {
                    <MagazziniCreateComponent
                        show={this.state.showCreateModal}
                        toggle={this.toggleShowCreateModal}
                        padri={this.state.padri}
                    />
                }

                {this.state.showUpdateModal ? (
                    <MagazziniUpdateComponent
                        data={this.state.selectedRecordForUpdate}
                        toggle={this.toggleShowUpdateModal}
                        padri={this.state.padri}
                    />
                ) : null}

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            <button
                                                className="btn btn-primary "
                                                id="nuovoModal"
                                                onClick={() => this.toggleShowCreateModal()}
                                            >
                                                <i className="tim-icons icon-simple-add"/>
                                                Nuovo
                                            </button>
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target="nuovoModal"
                                                delay={0}
                                            >
                                                Crea un nuovo articolo
                                                <br/> ( Hotkey F11)
                                            </UncontrolledTooltip>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button
                                                id="refresh"
                                                onClick={() => {
                                                    this.caricaMagazzini();
                                                }}
                                                className="btn btn-primary btn-fab btn-icon "
                                            >
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target="refresh"
                                                delay={0}
                                            >
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista Magazzini</CardTitle>
                                    <Fade when={this.props.loading}>
                                        <CardSubtitle>
                                            Caricamento in corso{" "}
                                            <i className="fa fa-spin fa-spinner"/>
                                        </CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {Header: "id", accessor: "ID"},
                                            {Header: "descrizione", accessor: "descrizione"},
                                            {Header: "padre", accessor: "idPadre"},
                                            {
                                                Header: "descrizione_padre",
                                                accessor: "descrizionePadre",
                                            },
                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false,
                                                show: !this.props.readOnly,
                                            },
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={"Avanti"}
                                        previousText={"Indietro"}
                                        pageText={"Pagina"}
                                        ofText={"di"}
                                        rowsText={"elementi"}
                                        noDataText={"Nessun dato"}
                                        loadingText={"Caricamento in corso"}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "magazzini.data", []),
        loading: get(state, "magazzini.loading", false),
    };

    if (data.data === null) data.data = [];

    return data;
};

export default connect(mapStateToProps, () => ({}))(MagazziniComponent);
