import React, {useState, useEffect, useRef} from "react";
import {Container} from "reactstrap";
import SignaturePad from 'react-signature-pad-wrapper';
import {isMobile} from 'react-device-detect';


import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    Input,
    Row,
    FormGroup,
    Label,
    Col
} from "reactstrap";
import GraphqlClient from "../../client/graphql.client";

function FirmaDifferita(props) {
    const signaturePad = useRef(null);
    const [proceduraTerminata, setProceduraTerminata] = useState(false);

    const salvaFirma = async () => {

        const urlParams = new URLSearchParams(props.location.search);
        const token = urlParams.get('token');

        try {
            const res = await GraphqlClient.firmaDifferita({token: token, firma: signaturePad.current.toDataURL().replace(/data:image\/png;base64,/gm, "")});
            setProceduraTerminata(true);
        } catch (e) {
            console.log('Firma differita errore: ', e);
            alert("Impossibile eseguire la firma " + e.toString())
        }

    };

    if (proceduraTerminata) {
        return <div>Firma salvata con successo</div>
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            height: '100%',
            padding: 5
        }}>
            {
                isMobile ?
                    <SignaturePad ref={signaturePad} redrawOnResize={true} options={{backgroundColor: "white"}}/>
                    :
                    <SignaturePad ref={signaturePad} redrawOnResize={true} options={{backgroundColor: "white"}}/>
            }
            <Button onClick={salvaFirma}>Salva firma</Button>
        </div>
    );
}

export default FirmaDifferita;
