import type { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
type Maybe<T> = T | null;
type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Json: any;
  Time: any;
  UnixDate: any;
};






type Articolo = {
  __typename?: 'Articolo';
  ID: Scalars['String'];
  descrizione?: Maybe<Scalars['String']>;
  barcodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  taglia?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

type Consegna = {
  __typename?: 'Consegna';
  ID: Scalars['String'];
  barcode?: Maybe<Scalars['String']>;
  idArticolo?: Maybe<Scalars['String']>;
  descrizioneArticolo?: Maybe<Scalars['String']>;
  idMagazzino?: Maybe<Scalars['String']>;
  descrizioneMagazzino?: Maybe<Scalars['String']>;
  destinatario?: Maybe<Scalars['String']>;
  firma?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Int']>;
  matricola?: Maybe<Scalars['Int']>;
  operatore?: Maybe<Scalars['String']>;
};

type DestinatarioConsegna = {
  __typename?: 'DestinatarioConsegna';
  matricola: Scalars['Int'];
  destinatario?: Maybe<Scalars['String']>;
  puntoDiRitiro?: Maybe<Scalars['String']>;
};

type EtichettaBusta = {
  __typename?: 'EtichettaBusta';
  Id: Scalars['String'];
  Zpl: Scalars['String'];
  UfficioRicezione: Scalars['String'];
};

type InputConsegna = {
  barcode: Scalars['String'];
  idMagazzino: Scalars['String'];
  idArticolo: Scalars['String'];
  destinatario: Scalars['String'];
  matricola: Scalars['Int'];
  firma: Scalars['String'];
};

type InputTrasferimento = {
  idArticolo?: Maybe<Scalars['String']>;
  quantita?: Maybe<Scalars['Int']>;
  idMagazzinoDiArrivo: Scalars['String'];
  idMagazzinoDiPartenza?: Maybe<Scalars['String']>;
};

type InputUtente = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  password: Scalars['String'];
  confermaPassword: Scalars['String'];
  salt: Scalars['String'];
  email: Scalars['String'];
  avatar: Scalars['String'];
  dataNascita: Scalars['Int'];
  provider: Scalars['String'];
  tags: Array<Scalars['String']>;
  ruolo: Scalars['String'];
  idMagazzino?: Maybe<Scalars['String']>;
  descrizioneMagazzino?: Maybe<Scalars['String']>;
};

type InputUtenteConPassword = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
  ruolo: Scalars['String'];
  password: Scalars['String'];
  confermaPassword: Scalars['String'];
  email: Scalars['String'];
  tags: Array<Scalars['String']>;
  idMagazzino?: Maybe<Scalars['String']>;
};


type Magazzino = {
  __typename?: 'Magazzino';
  ID: Scalars['String'];
  descrizione: Scalars['String'];
  idPadre?: Maybe<Scalars['String']>;
  descrizionePadre?: Maybe<Scalars['String']>;
};

type Movimento = {
  __typename?: 'Movimento';
  ID: Scalars['String'];
  idArticolo: Scalars['String'];
  descrizioneArticolo: Scalars['String'];
  idMagazzino: Scalars['String'];
  descrizioneMagazzino: Scalars['String'];
  quantita: Scalars['Int'];
  tipo: Scalars['String'];
  idRiferimento: Scalars['String'];
  data?: Maybe<Scalars['Int']>;
};

type Mutation = {
  __typename?: 'Mutation';
  pingMutation: Scalars['String'];
  creaAnagraficaPoste?: Maybe<RaiAnagraficaPoste>;
  aggiornaAnagraficaPoste?: Maybe<RaiAnagraficaPoste>;
  eliminaAnagraficaPoste?: Maybe<RaiAnagraficaPoste>;
  inserisciArticolo?: Maybe<Articolo>;
  aggiornaArticolo?: Maybe<Articolo>;
  eliminaArticolo?: Maybe<Articolo>;
  inserisciBarcode?: Maybe<Articolo>;
  eliminaBarcode?: Maybe<Articolo>;
  creaBustaRai?: Maybe<RaiBusta>;
  bustaAltraCompetenza?: Maybe<RaiBusta>;
  consegnaFallita?: Maybe<RaiBusta>;
  eliminaBusta?: Maybe<RaiBusta>;
  firmaBusta?: Maybe<RaiBusta>;
  modificaBusta?: Maybe<RaiBusta>;
  richiestaFirmaDifferita?: Maybe<RaiBusta>;
  firmaDifferita?: Maybe<RaiBusta>;
  creaConsegnaMassivaPoste?: Maybe<RaiPosteConsegnaMassiva>;
  firmaConsegnaMassiva?: Maybe<RaiPosteConsegnaMassiva>;
  creaTrasferimentoUfficioPoste?: Maybe<RaiPosteTrasferimentoUfficio>;
  firmaTrasferimentoUfficio?: Maybe<RaiPosteTrasferimentoUfficio>;
  inserisciConsegna?: Maybe<Consegna>;
  eliminaConsegna?: Maybe<Consegna>;
  inserisciDestinatarioConsegna?: Maybe<DestinatarioConsegna>;
  rimuoviDestinatarioConsegna?: Maybe<DestinatarioConsegna>;
  inserisciMagazzino?: Maybe<Magazzino>;
  aggiornaMagazzino?: Maybe<Magazzino>;
  eliminaMagazzino?: Maybe<Magazzino>;
  inserisciMovimento?: Maybe<Movimento>;
  aggiornaMovimento?: Maybe<Movimento>;
  eliminaMovimento?: Maybe<Movimento>;
  inserisciTrasferimento?: Maybe<Trasferimento>;
  aggiornaTrasferimento?: Maybe<Trasferimento>;
  eliminaTrasferimento?: Maybe<Trasferimento>;
  creaUtente: TokenDetails;
  aggiornaUtente: Utente;
  eliminaUtente: Utente;
  rimuoviMagazzinoDaUtente: Utente;
};


type MutationCreaAnagraficaPosteArgs = {
  email?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  piano?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  stanza?: Maybe<Scalars['String']>;
  emailPreventiva?: Maybe<Scalars['Boolean']>;
  descrizione?: Maybe<Scalars['String']>;
  cognomenome?: Maybe<Scalars['String']>;
  societa?: Maybe<Scalars['String']>;
};


type MutationAggiornaAnagraficaPosteArgs = {
  societa?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  cognomenome?: Maybe<Scalars['String']>;
  piano?: Maybe<Scalars['String']>;
  stanza?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  emailPreventiva?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  indirizzo?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
};


type MutationEliminaAnagraficaPosteArgs = {
  id: Scalars['String'];
};


type MutationInserisciArticoloArgs = {
  input?: Maybe<NuovoArticolo>;
};


type MutationAggiornaArticoloArgs = {
  id: Scalars['String'];
  input?: Maybe<NuovoArticolo>;
};


type MutationEliminaArticoloArgs = {
  id: Scalars['String'];
};


type MutationInserisciBarcodeArgs = {
  id: Scalars['String'];
  barcode: Scalars['String'];
};


type MutationEliminaBarcodeArgs = {
  id: Scalars['String'];
  barcode: Scalars['String'];
};


type MutationCreaBustaRaiArgs = {
  mittente?: Maybe<Scalars['String']>;
  destinatario?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  foto?: Maybe<Scalars['String']>;
  emailPreventiva?: Maybe<Scalars['Boolean']>;
  ufficioRicezione?: Maybe<Scalars['String']>;
};


type MutationBustaAltraCompetenzaArgs = {
  id?: Maybe<Scalars['String']>;
};


type MutationConsegnaFallitaArgs = {
  id?: Maybe<Scalars['String']>;
};


type MutationEliminaBustaArgs = {
  id?: Maybe<Scalars['String']>;
};


type MutationFirmaBustaArgs = {
  id?: Maybe<Scalars['String']>;
  firma?: Maybe<Scalars['String']>;
};


type MutationModificaBustaArgs = {
  id: Scalars['String'];
  mittente?: Maybe<Scalars['String']>;
  destinatario?: Maybe<Scalars['String']>;
};


type MutationRichiestaFirmaDifferitaArgs = {
  id: Scalars['String'];
  tipologia: Scalars['String'];
  contatto: Scalars['String'];
};


type MutationFirmaDifferitaArgs = {
  token: Scalars['String'];
  firma: Scalars['String'];
};


type MutationCreaConsegnaMassivaPosteArgs = {
  codiciBuste?: Maybe<Array<Maybe<Scalars['String']>>>;
  operatore?: Maybe<Scalars['String']>;
  ufficio?: Maybe<Scalars['String']>;
};


type MutationFirmaConsegnaMassivaArgs = {
  id?: Maybe<Scalars['String']>;
  firma?: Maybe<Scalars['String']>;
  operatore?: Maybe<Scalars['String']>;
};


type MutationCreaTrasferimentoUfficioPosteArgs = {
  codiciBuste?: Maybe<Array<Maybe<Scalars['String']>>>;
  ufficioMittente?: Maybe<Scalars['String']>;
  ufficioDestinatario?: Maybe<Scalars['String']>;
  operatore?: Maybe<Scalars['String']>;
};


type MutationFirmaTrasferimentoUfficioArgs = {
  id?: Maybe<Scalars['String']>;
  firma?: Maybe<Scalars['String']>;
  operatore?: Maybe<Scalars['String']>;
};


type MutationInserisciConsegnaArgs = {
  input: InputConsegna;
};


type MutationEliminaConsegnaArgs = {
  id: Scalars['String'];
};


type MutationInserisciDestinatarioConsegnaArgs = {
  matricola: Scalars['Int'];
  nome: Scalars['String'];
  cognome: Scalars['String'];
  ritiro: Scalars['String'];
};


type MutationRimuoviDestinatarioConsegnaArgs = {
  matricola: Scalars['Int'];
};


type MutationInserisciMagazzinoArgs = {
  descrizione: Scalars['String'];
  idPadre?: Maybe<Scalars['String']>;
};


type MutationAggiornaMagazzinoArgs = {
  id: Scalars['String'];
  descrizione?: Maybe<Scalars['String']>;
  idPadre?: Maybe<Scalars['String']>;
};


type MutationEliminaMagazzinoArgs = {
  id: Scalars['String'];
};


type MutationInserisciMovimentoArgs = {
  idArticolo: Scalars['String'];
  idMagazzino: Scalars['String'];
  tipo: Scalars['String'];
  quantita: Scalars['Int'];
  idRiferimento: Scalars['String'];
};


type MutationAggiornaMovimentoArgs = {
  id: Scalars['String'];
  idArticolo: Scalars['String'];
  idMagazzino: Scalars['String'];
  tipo: Scalars['String'];
  quantita: Scalars['Int'];
  idRiferimento: Scalars['String'];
};


type MutationEliminaMovimentoArgs = {
  id: Scalars['String'];
};


type MutationInserisciTrasferimentoArgs = {
  input?: Maybe<InputTrasferimento>;
};


type MutationAggiornaTrasferimentoArgs = {
  id: Scalars['String'];
  input?: Maybe<InputTrasferimento>;
};


type MutationEliminaTrasferimentoArgs = {
  id: Scalars['String'];
};


type MutationCreaUtenteArgs = {
  utente?: Maybe<InputUtenteConPassword>;
};


type MutationAggiornaUtenteArgs = {
  email: Scalars['String'];
  tags: Array<Scalars['String']>;
  idMagazzino?: Maybe<Scalars['String']>;
};


type MutationEliminaUtenteArgs = {
  email: Scalars['String'];
};


type MutationRimuoviMagazzinoDaUtenteArgs = {
  email: Scalars['String'];
};

type NuovoArticolo = {
  descrizione?: Maybe<Scalars['String']>;
  barcodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  taglia?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};

type Query = {
  __typename?: 'Query';
  ping: Scalars['String'];
  ottieniAnagrafichePoste?: Maybe<Array<Maybe<RaiAnagraficaPoste>>>;
  ottieniAnagrafichePosteFiltrate?: Maybe<Array<Maybe<RaiAnagraficaPoste>>>;
  ottieniArticolo?: Maybe<Articolo>;
  ottieniArticoli?: Maybe<Array<Maybe<Articolo>>>;
  ottieniArticoliPerMagazzino?: Maybe<Array<Maybe<Articolo>>>;
  ottieniArticoloPerBarcode?: Maybe<Articolo>;
  OttieniBusta?: Maybe<RaiBusta>;
  ottieniBusteRai?: Maybe<Array<Maybe<RaiBusta>>>;
  ottieniBusteRaiGiornaliere?: Maybe<Array<Maybe<RaiBusta>>>;
  ristampaEtichetta?: Maybe<RaiBusta>;
  ottieniFotoBusta: Scalars['String'];
  ottieniFirmaBusta: Scalars['String'];
  scaricaReportBuste?: Maybe<Scalars['String']>;
  scaricaReportBusteCompatto?: Maybe<Scalars['String']>;
  ottieniConsegnaMassiva?: Maybe<RaiPosteConsegnaMassiva>;
  ottieniConsegneMassive?: Maybe<Array<Maybe<RaiPosteConsegnaMassiva>>>;
  ottieniTrasferimentoUfficio?: Maybe<RaiPosteTrasferimentoUfficio>;
  ottieniConsegna?: Maybe<Consegna>;
  ottieniConsegne?: Maybe<Array<Maybe<Consegna>>>;
  ottieniFirmaConsegna?: Maybe<Scalars['String']>;
  ottieniConsegnePerDestinatario?: Maybe<Array<Maybe<Consegna>>>;
  quantitaArticoliConsegnatiPerMagazzino?: Maybe<Scalars['Int']>;
  /**  start = data piu' recente / end = data piu' remota (estremi inclusi) */
  ottieniCsvConsegneConIntervallo?: Maybe<Scalars['String']>;
  ottieniCsvConsegne?: Maybe<Scalars['String']>;
  ottieniTotaleConsegneOperatori?: Maybe<Array<Maybe<TotaleConsegneOperatori>>>;
  ottieniTotaleConsegneMagazzino?: Maybe<Array<Maybe<TotaleConsegneMagazzino>>>;
  ottieniDestinatariConsegna?: Maybe<Array<Maybe<DestinatarioConsegna>>>;
  ottieniDestinatariConsegnaPerNome?: Maybe<Array<Maybe<DestinatarioConsegna>>>;
  ottieniDestinatariConsegnaPerMatricola?: Maybe<DestinatarioConsegna>;
  ottieniDestiantariConsegnaPerFullText?: Maybe<Array<Maybe<DestinatarioConsegna>>>;
  ottieniMagazzino?: Maybe<Magazzino>;
  ottieniMagazzini?: Maybe<Array<Maybe<Magazzino>>>;
  ottieniMovimenti?: Maybe<Array<Maybe<Movimento>>>;
  ottieniMovimento: Movimento;
  ottieniMovimentiPerArticolo?: Maybe<Array<Maybe<Movimento>>>;
  ottieniMovimentiPerMagazzino?: Maybe<Array<Maybe<Movimento>>>;
  ottieniTotaleMagazzinoPerArticolo?: Maybe<Scalars['Int']>;
  ottieniTotaleMagazzino?: Maybe<Scalars['Int']>;
  ottieniTotaleMagazzini?: Maybe<Array<Maybe<TotaleArticoloMagazzino>>>;
  ottieniTrasferimento?: Maybe<Trasferimento>;
  ottieniTrasferimenti?: Maybe<Array<Maybe<Trasferimento>>>;
  utenti?: Maybe<Array<Maybe<Utente>>>;
  utenteLoggato: Utente;
  appleSignIn: TokenDetails;
  appleSignUp: TokenDetails;
  login: TokenDetails;
  basicLogin: TokenDetails;
  getNewAccessToken: TokenDetails;
};


type QueryOttieniAnagrafichePosteFiltrateArgs = {
  filtro?: Maybe<Scalars['String']>;
};


type QueryOttieniArticoloArgs = {
  id: Scalars['String'];
};


type QueryOttieniArticoliPerMagazzinoArgs = {
  idMagazzino: Scalars['String'];
};


type QueryOttieniArticoloPerBarcodeArgs = {
  barcode: Scalars['String'];
};


type QueryOttieniBustaArgs = {
  id: Scalars['String'];
};


type QueryOttieniBusteRaiArgs = {
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
};


type QueryOttieniBusteRaiGiornaliereArgs = {
  ufficioRicezione?: Maybe<Scalars['String']>;
};


type QueryRistampaEtichettaArgs = {
  id: Scalars['String'];
};


type QueryOttieniFotoBustaArgs = {
  id: Scalars['String'];
};


type QueryOttieniFirmaBustaArgs = {
  id: Scalars['String'];
};


type QueryScaricaReportBusteArgs = {
  idBuste: Array<Scalars['String']>;
};


type QueryScaricaReportBusteCompattoArgs = {
  idBuste: Array<Scalars['String']>;
};


type QueryOttieniConsegnaMassivaArgs = {
  id?: Maybe<Scalars['String']>;
};


type QueryOttieniTrasferimentoUfficioArgs = {
  id?: Maybe<Scalars['String']>;
};


type QueryOttieniConsegnaArgs = {
  id: Scalars['String'];
};


type QueryOttieniFirmaConsegnaArgs = {
  id: Scalars['String'];
};


type QueryOttieniConsegnePerDestinatarioArgs = {
  destinatario: Scalars['String'];
};


type QueryQuantitaArticoliConsegnatiPerMagazzinoArgs = {
  idMagazzino: Scalars['String'];
};


type QueryOttieniCsvConsegneConIntervalloArgs = {
  start: Scalars['Int'];
  end: Scalars['Int'];
};


type QueryOttieniDestinatariConsegnaPerNomeArgs = {
  nome: Scalars['String'];
  cognome: Scalars['String'];
};


type QueryOttieniDestinatariConsegnaPerMatricolaArgs = {
  matricola: Scalars['Int'];
};


type QueryOttieniDestiantariConsegnaPerFullTextArgs = {
  query: Scalars['String'];
};


type QueryOttieniMagazzinoArgs = {
  id: Scalars['String'];
};


type QueryOttieniMovimentoArgs = {
  id: Scalars['String'];
};


type QueryOttieniMovimentiPerArticoloArgs = {
  idArticolo: Scalars['String'];
};


type QueryOttieniMovimentiPerMagazzinoArgs = {
  idMagazzino: Scalars['String'];
};


type QueryOttieniTotaleMagazzinoPerArticoloArgs = {
  idMagazzino: Scalars['String'];
  idArticolo: Scalars['String'];
};


type QueryOttieniTotaleMagazzinoArgs = {
  idMagazzino: Scalars['String'];
};


type QueryOttieniTrasferimentoArgs = {
  id: Scalars['String'];
};


type QueryAppleSignInArgs = {
  identityToken: Scalars['String'];
};


type QueryAppleSignUpArgs = {
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


type QueryLoginArgs = {
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


type QueryBasicLoginArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};


type QueryGetNewAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

type RaiAnagraficaPoste = {
  __typename?: 'RaiAnagraficaPoste';
  id: Scalars['String'];
  cap?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  cognomenome?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailPreventiva?: Maybe<Scalars['Boolean']>;
  indirizzo?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  piano?: Maybe<Scalars['String']>;
  societa?: Maybe<Scalars['String']>;
  stanza?: Maybe<Scalars['String']>;
};

type RaiBusta = {
  __typename?: 'RaiBusta';
  barcode?: Maybe<Scalars['String']>;
  destinatario?: Maybe<Scalars['Json']>;
  emailPreventiva?: Maybe<Scalars['Boolean']>;
  firma?: Maybe<Scalars['String']>;
  foto?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mittente?: Maybe<Scalars['Json']>;
  operatoreLoggatoFirma?: Maybe<Scalars['String']>;
  operatoreLoggatoScansione?: Maybe<Scalars['String']>;
  operatoreUltimaModifica?: Maybe<Scalars['String']>;
  oraConsegna?: Maybe<Scalars['Int']>;
  oraFirma?: Maybe<Scalars['Int']>;
  oraRicezione?: Maybe<Scalars['Int']>;
  orariTentativiDiConsegna?: Maybe<Array<Maybe<Scalars['Int']>>>;
  stato?: Maybe<Scalars['String']>;
  tentativiDiConsegnaEffetuati?: Maybe<Scalars['Int']>;
  trasferito?: Maybe<Scalars['Boolean']>;
  ufficioRicezione?: Maybe<Scalars['String']>;
  ufficioTrasferimento?: Maybe<Scalars['String']>;
  normalized?: Maybe<Scalars['Boolean']>;
};

type RaiPosteConsegnaMassiva = {
  __typename?: 'RaiPosteConsegnaMassiva';
  codiciBuste?: Maybe<Array<Maybe<Scalars['String']>>>;
  firma?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  operatore?: Maybe<Scalars['String']>;
  operatoreFirmatario?: Maybe<Scalars['String']>;
  oraCreazione?: Maybe<Scalars['Int']>;
  oraFirma?: Maybe<Scalars['Int']>;
  stato?: Maybe<Scalars['String']>;
  ufficio?: Maybe<Scalars['String']>;
};

type RaiPosteTrasferimentoUfficio = {
  __typename?: 'RaiPosteTrasferimentoUfficio';
  codiciBuste?: Maybe<Array<Maybe<Scalars['String']>>>;
  firma?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  operatore?: Maybe<Scalars['String']>;
  operatoreFirmatario?: Maybe<Scalars['String']>;
  oraCreazione?: Maybe<Scalars['Int']>;
  oraFirma?: Maybe<Scalars['Int']>;
  stato?: Maybe<Scalars['String']>;
  ufficioDestinatario?: Maybe<Scalars['String']>;
  ufficioMittente?: Maybe<Scalars['String']>;
};

enum Role {
  Amministratore = 'AMMINISTRATORE',
  Utente = 'UTENTE'
}

type SignedToken = {
  __typename?: 'SignedToken';
  id: Scalars['String'];
  content: Scalars['String'];
  createdAt: Scalars['Int'];
  expiresAt: Scalars['Int'];
};


type TokenDetails = {
  __typename?: 'TokenDetails';
  accessToken?: Maybe<Scalars['String']>;
  refreshToken: Scalars['String'];
  accessUuid: Scalars['String'];
  refreshUuid: Scalars['String'];
  atExpires: Scalars['Int'];
  rtExpires: Scalars['Int'];
  utente: Utente;
};

type TotaleArticoloMagazzino = {
  __typename?: 'TotaleArticoloMagazzino';
  idArticolo: Scalars['String'];
  descrizioneArticolo: Scalars['String'];
  quantita: Scalars['Int'];
  idMagazzino: Scalars['String'];
  descrizioneMagazzino: Scalars['String'];
};

type TotaleConsegneMagazzino = {
  __typename?: 'TotaleConsegneMagazzino';
  magazzino?: Maybe<Scalars['String']>;
  articolo?: Maybe<Scalars['String']>;
  totale?: Maybe<Scalars['Int']>;
};

type TotaleConsegneOperatori = {
  __typename?: 'TotaleConsegneOperatori';
  operatore?: Maybe<Scalars['String']>;
  articolo?: Maybe<Scalars['String']>;
  totale?: Maybe<Scalars['Int']>;
};

type Trasferimento = {
  __typename?: 'Trasferimento';
  ID: Scalars['String'];
  idArticolo?: Maybe<Scalars['String']>;
  descrizioneArticolo?: Maybe<Scalars['String']>;
  quantita?: Maybe<Scalars['Int']>;
  idMagazzinoDiArrivo: Scalars['String'];
  idMagazzinoDiPartenza?: Maybe<Scalars['String']>;
  desMagazzinoArrivo?: Maybe<Scalars['String']>;
  desMagazzinoPartenza?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Int']>;
};


type Utente = {
  __typename?: 'Utente';
  nome: Scalars['String'];
  cognome: Scalars['String'];
  email: Scalars['String'];
  ruolo: Scalars['String'];
  avatar: Scalars['String'];
  dataNascita: Scalars['Int'];
  provider: Scalars['String'];
  dataRegistrazione: Scalars['Int'];
  createdAt?: Maybe<Scalars['Time']>;
  tags: Array<Scalars['String']>;
  idMagazzino?: Maybe<Scalars['String']>;
  descrizioneMagazzino?: Maybe<Scalars['String']>;
};

type AggiornaAnagraficaPosteMutationVariables = Exact<{
  societa?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  cognomenome?: Maybe<Scalars['String']>;
  piano?: Maybe<Scalars['String']>;
  stanza?: Maybe<Scalars['String']>;
  descrizione?: Maybe<Scalars['String']>;
  emailPreventiva?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  indirizzo?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
}>;


type AggiornaAnagraficaPosteMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaAnagraficaPoste?: Maybe<(
    { __typename?: 'RaiAnagraficaPoste' }
    & Pick<RaiAnagraficaPoste, 'id' | 'cap' | 'citta' | 'cognome' | 'cognomenome' | 'descrizione' | 'email' | 'emailPreventiva' | 'indirizzo' | 'nome' | 'piano' | 'societa' | 'stanza'>
  )> }
);

type AggiornaArticoloMutationVariables = Exact<{
  id: Scalars['String'];
  input?: Maybe<NuovoArticolo>;
}>;


type AggiornaArticoloMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaArticolo?: Maybe<(
    { __typename?: 'Articolo' }
    & Pick<Articolo, 'ID' | 'descrizione' | 'barcodes' | 'taglia' | 'note'>
  )> }
);

type AggiornaMagazzinoMutationVariables = Exact<{
  id: Scalars['String'];
  descrizione?: Maybe<Scalars['String']>;
  idPadre?: Maybe<Scalars['String']>;
}>;


type AggiornaMagazzinoMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaMagazzino?: Maybe<(
    { __typename?: 'Magazzino' }
    & Pick<Magazzino, 'ID' | 'descrizione' | 'idPadre' | 'descrizionePadre'>
  )> }
);

type AggiornaMovimentoMutationVariables = Exact<{
  id: Scalars['String'];
  idArticolo: Scalars['String'];
  idMagazzino: Scalars['String'];
  tipo: Scalars['String'];
  quantita: Scalars['Int'];
  idRiferimento: Scalars['String'];
}>;


type AggiornaMovimentoMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaMovimento?: Maybe<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'quantita' | 'tipo' | 'idRiferimento' | 'data'>
  )> }
);

type AggiornaTrasferimentoMutationVariables = Exact<{
  id: Scalars['String'];
  input?: Maybe<InputTrasferimento>;
}>;


type AggiornaTrasferimentoMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaTrasferimento?: Maybe<(
    { __typename?: 'Trasferimento' }
    & Pick<Trasferimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'quantita' | 'idMagazzinoDiArrivo' | 'idMagazzinoDiPartenza' | 'desMagazzinoArrivo' | 'desMagazzinoPartenza' | 'data'>
  )> }
);

type AggiornaUtenteMutationVariables = Exact<{
  email: Scalars['String'];
  tags: Array<Scalars['String']>;
  idMagazzino?: Maybe<Scalars['String']>;
}>;


type AggiornaUtenteMutation = (
  { __typename?: 'Mutation' }
  & { aggiornaUtente: (
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
  ) }
);

type BustaAltraCompetenzaMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


type BustaAltraCompetenzaMutation = (
  { __typename?: 'Mutation' }
  & { bustaAltraCompetenza?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type ConsegnaFallitaMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


type ConsegnaFallitaMutation = (
  { __typename?: 'Mutation' }
  & { consegnaFallita?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type CreaAnagraficaPosteMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  cap?: Maybe<Scalars['String']>;
  piano?: Maybe<Scalars['String']>;
  nome?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  indirizzo?: Maybe<Scalars['String']>;
  citta?: Maybe<Scalars['String']>;
  stanza?: Maybe<Scalars['String']>;
  emailPreventiva?: Maybe<Scalars['Boolean']>;
  descrizione?: Maybe<Scalars['String']>;
  cognomenome?: Maybe<Scalars['String']>;
  societa?: Maybe<Scalars['String']>;
}>;


type CreaAnagraficaPosteMutation = (
  { __typename?: 'Mutation' }
  & { creaAnagraficaPoste?: Maybe<(
    { __typename?: 'RaiAnagraficaPoste' }
    & Pick<RaiAnagraficaPoste, 'id' | 'cap' | 'citta' | 'cognome' | 'cognomenome' | 'descrizione' | 'email' | 'emailPreventiva' | 'indirizzo' | 'nome' | 'piano' | 'societa' | 'stanza'>
  )> }
);

type CreaBustaRaiMutationVariables = Exact<{
  mittente?: Maybe<Scalars['String']>;
  destinatario?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  foto?: Maybe<Scalars['String']>;
  emailPreventiva?: Maybe<Scalars['Boolean']>;
  ufficioRicezione?: Maybe<Scalars['String']>;
}>;


type CreaBustaRaiMutation = (
  { __typename?: 'Mutation' }
  & { creaBustaRai?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type CreaConsegnaMassivaPosteMutationVariables = Exact<{
  codiciBuste?: Maybe<Array<Maybe<Scalars['String']>>>;
  operatore?: Maybe<Scalars['String']>;
  ufficio?: Maybe<Scalars['String']>;
}>;


type CreaConsegnaMassivaPosteMutation = (
  { __typename?: 'Mutation' }
  & { creaConsegnaMassivaPoste?: Maybe<(
    { __typename?: 'RaiPosteConsegnaMassiva' }
    & Pick<RaiPosteConsegnaMassiva, 'codiciBuste' | 'firma' | 'id' | 'operatore' | 'operatoreFirmatario' | 'oraCreazione' | 'oraFirma' | 'stato' | 'ufficio'>
  )> }
);

type CreaTrasferimentoUfficioPosteMutationVariables = Exact<{
  codiciBuste?: Maybe<Array<Maybe<Scalars['String']>>>;
  ufficioMittente?: Maybe<Scalars['String']>;
  ufficioDestinatario?: Maybe<Scalars['String']>;
  operatore?: Maybe<Scalars['String']>;
}>;


type CreaTrasferimentoUfficioPosteMutation = (
  { __typename?: 'Mutation' }
  & { creaTrasferimentoUfficioPoste?: Maybe<(
    { __typename?: 'RaiPosteTrasferimentoUfficio' }
    & Pick<RaiPosteTrasferimentoUfficio, 'codiciBuste' | 'firma' | 'id' | 'operatore' | 'operatoreFirmatario' | 'oraCreazione' | 'oraFirma' | 'stato' | 'ufficioDestinatario' | 'ufficioMittente'>
  )> }
);

type CreaUtenteMutationVariables = Exact<{
  utente?: Maybe<InputUtenteConPassword>;
}>;


type CreaUtenteMutation = (
  { __typename?: 'Mutation' }
  & { creaUtente: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
    ) }
  ) }
);

type EliminaAnagraficaPosteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaAnagraficaPosteMutation = (
  { __typename?: 'Mutation' }
  & { eliminaAnagraficaPoste?: Maybe<(
    { __typename?: 'RaiAnagraficaPoste' }
    & Pick<RaiAnagraficaPoste, 'id' | 'cap' | 'citta' | 'cognome' | 'cognomenome' | 'descrizione' | 'email' | 'emailPreventiva' | 'indirizzo' | 'nome' | 'piano' | 'societa' | 'stanza'>
  )> }
);

type EliminaArticoloMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaArticoloMutation = (
  { __typename?: 'Mutation' }
  & { eliminaArticolo?: Maybe<(
    { __typename?: 'Articolo' }
    & Pick<Articolo, 'ID' | 'descrizione' | 'barcodes' | 'taglia' | 'note'>
  )> }
);

type EliminaBarcodeMutationVariables = Exact<{
  id: Scalars['String'];
  barcode: Scalars['String'];
}>;


type EliminaBarcodeMutation = (
  { __typename?: 'Mutation' }
  & { eliminaBarcode?: Maybe<(
    { __typename?: 'Articolo' }
    & Pick<Articolo, 'ID' | 'descrizione' | 'barcodes' | 'taglia' | 'note'>
  )> }
);

type EliminaBustaMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


type EliminaBustaMutation = (
  { __typename?: 'Mutation' }
  & { eliminaBusta?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type EliminaConsegnaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaConsegnaMutation = (
  { __typename?: 'Mutation' }
  & { eliminaConsegna?: Maybe<(
    { __typename?: 'Consegna' }
    & Pick<Consegna, 'ID' | 'barcode' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'destinatario' | 'firma' | 'data' | 'matricola' | 'operatore'>
  )> }
);

type EliminaMagazzinoMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaMagazzinoMutation = (
  { __typename?: 'Mutation' }
  & { eliminaMagazzino?: Maybe<(
    { __typename?: 'Magazzino' }
    & Pick<Magazzino, 'ID' | 'descrizione' | 'idPadre' | 'descrizionePadre'>
  )> }
);

type EliminaMovimentoMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaMovimentoMutation = (
  { __typename?: 'Mutation' }
  & { eliminaMovimento?: Maybe<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'quantita' | 'tipo' | 'idRiferimento' | 'data'>
  )> }
);

type EliminaTrasferimentoMutationVariables = Exact<{
  id: Scalars['String'];
}>;


type EliminaTrasferimentoMutation = (
  { __typename?: 'Mutation' }
  & { eliminaTrasferimento?: Maybe<(
    { __typename?: 'Trasferimento' }
    & Pick<Trasferimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'quantita' | 'idMagazzinoDiArrivo' | 'idMagazzinoDiPartenza' | 'desMagazzinoArrivo' | 'desMagazzinoPartenza' | 'data'>
  )> }
);

type EliminaUtenteMutationVariables = Exact<{
  email: Scalars['String'];
}>;


type EliminaUtenteMutation = (
  { __typename?: 'Mutation' }
  & { eliminaUtente: (
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
  ) }
);

type FirmaBustaMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  firma?: Maybe<Scalars['String']>;
}>;


type FirmaBustaMutation = (
  { __typename?: 'Mutation' }
  & { firmaBusta?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type FirmaConsegnaMassivaMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  firma?: Maybe<Scalars['String']>;
  operatore?: Maybe<Scalars['String']>;
}>;


type FirmaConsegnaMassivaMutation = (
  { __typename?: 'Mutation' }
  & { firmaConsegnaMassiva?: Maybe<(
    { __typename?: 'RaiPosteConsegnaMassiva' }
    & Pick<RaiPosteConsegnaMassiva, 'codiciBuste' | 'firma' | 'id' | 'operatore' | 'operatoreFirmatario' | 'oraCreazione' | 'oraFirma' | 'stato' | 'ufficio'>
  )> }
);

type FirmaDifferitaMutationVariables = Exact<{
  token: Scalars['String'];
  firma: Scalars['String'];
}>;


type FirmaDifferitaMutation = (
  { __typename?: 'Mutation' }
  & { firmaDifferita?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type FirmaTrasferimentoUfficioMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  firma?: Maybe<Scalars['String']>;
  operatore?: Maybe<Scalars['String']>;
}>;


type FirmaTrasferimentoUfficioMutation = (
  { __typename?: 'Mutation' }
  & { firmaTrasferimentoUfficio?: Maybe<(
    { __typename?: 'RaiPosteTrasferimentoUfficio' }
    & Pick<RaiPosteTrasferimentoUfficio, 'codiciBuste' | 'firma' | 'id' | 'operatore' | 'operatoreFirmatario' | 'oraCreazione' | 'oraFirma' | 'stato' | 'ufficioDestinatario' | 'ufficioMittente'>
  )> }
);

type InserisciArticoloMutationVariables = Exact<{
  input?: Maybe<NuovoArticolo>;
}>;


type InserisciArticoloMutation = (
  { __typename?: 'Mutation' }
  & { inserisciArticolo?: Maybe<(
    { __typename?: 'Articolo' }
    & Pick<Articolo, 'ID' | 'descrizione' | 'barcodes' | 'taglia' | 'note'>
  )> }
);

type InserisciBarcodeMutationVariables = Exact<{
  id: Scalars['String'];
  barcode: Scalars['String'];
}>;


type InserisciBarcodeMutation = (
  { __typename?: 'Mutation' }
  & { inserisciBarcode?: Maybe<(
    { __typename?: 'Articolo' }
    & Pick<Articolo, 'ID' | 'descrizione' | 'barcodes' | 'taglia' | 'note'>
  )> }
);

type InserisciConsegnaMutationVariables = Exact<{
  input: InputConsegna;
}>;


type InserisciConsegnaMutation = (
  { __typename?: 'Mutation' }
  & { inserisciConsegna?: Maybe<(
    { __typename?: 'Consegna' }
    & Pick<Consegna, 'ID' | 'barcode' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'destinatario' | 'firma' | 'data' | 'matricola' | 'operatore'>
  )> }
);

type InserisciDestinatarioConsegnaMutationVariables = Exact<{
  matricola: Scalars['Int'];
  nome: Scalars['String'];
  cognome: Scalars['String'];
  ritiro: Scalars['String'];
}>;


type InserisciDestinatarioConsegnaMutation = (
  { __typename?: 'Mutation' }
  & { inserisciDestinatarioConsegna?: Maybe<(
    { __typename?: 'DestinatarioConsegna' }
    & Pick<DestinatarioConsegna, 'matricola' | 'destinatario' | 'puntoDiRitiro'>
  )> }
);

type InserisciMagazzinoMutationVariables = Exact<{
  descrizione: Scalars['String'];
  idPadre?: Maybe<Scalars['String']>;
}>;


type InserisciMagazzinoMutation = (
  { __typename?: 'Mutation' }
  & { inserisciMagazzino?: Maybe<(
    { __typename?: 'Magazzino' }
    & Pick<Magazzino, 'ID' | 'descrizione' | 'idPadre' | 'descrizionePadre'>
  )> }
);

type InserisciMovimentoMutationVariables = Exact<{
  idArticolo: Scalars['String'];
  idMagazzino: Scalars['String'];
  tipo: Scalars['String'];
  quantita: Scalars['Int'];
  idRiferimento: Scalars['String'];
}>;


type InserisciMovimentoMutation = (
  { __typename?: 'Mutation' }
  & { inserisciMovimento?: Maybe<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'quantita' | 'tipo' | 'idRiferimento' | 'data'>
  )> }
);

type InserisciTrasferimentoMutationVariables = Exact<{
  input?: Maybe<InputTrasferimento>;
}>;


type InserisciTrasferimentoMutation = (
  { __typename?: 'Mutation' }
  & { inserisciTrasferimento?: Maybe<(
    { __typename?: 'Trasferimento' }
    & Pick<Trasferimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'quantita' | 'idMagazzinoDiArrivo' | 'idMagazzinoDiPartenza' | 'desMagazzinoArrivo' | 'desMagazzinoPartenza' | 'data'>
  )> }
);

type ModificaBustaMutationVariables = Exact<{
  id: Scalars['String'];
  mittente?: Maybe<Scalars['String']>;
  destinatario?: Maybe<Scalars['String']>;
}>;


type ModificaBustaMutation = (
  { __typename?: 'Mutation' }
  & { modificaBusta?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type PingMutationMutationVariables = Exact<{ [key: string]: never; }>;


type PingMutationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'pingMutation'>
);

type RichiestaFirmaDifferitaMutationVariables = Exact<{
  id: Scalars['String'];
  tipologia: Scalars['String'];
  contatto: Scalars['String'];
}>;


type RichiestaFirmaDifferitaMutation = (
  { __typename?: 'Mutation' }
  & { richiestaFirmaDifferita?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type RimuoviDestinatarioConsegnaMutationVariables = Exact<{
  matricola: Scalars['Int'];
}>;


type RimuoviDestinatarioConsegnaMutation = (
  { __typename?: 'Mutation' }
  & { rimuoviDestinatarioConsegna?: Maybe<(
    { __typename?: 'DestinatarioConsegna' }
    & Pick<DestinatarioConsegna, 'matricola' | 'destinatario' | 'puntoDiRitiro'>
  )> }
);

type RimuoviMagazzinoDaUtenteMutationVariables = Exact<{
  email: Scalars['String'];
}>;


type RimuoviMagazzinoDaUtenteMutation = (
  { __typename?: 'Mutation' }
  & { rimuoviMagazzinoDaUtente: (
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
  ) }
);

type OttieniBustaQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type OttieniBustaQuery = (
  { __typename?: 'Query' }
  & { OttieniBusta?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type AppleSignInQueryVariables = Exact<{
  identityToken: Scalars['String'];
}>;


type AppleSignInQuery = (
  { __typename?: 'Query' }
  & { appleSignIn: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
    ) }
  ) }
);

type AppleSignUpQueryVariables = Exact<{
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


type AppleSignUpQuery = (
  { __typename?: 'Query' }
  & { appleSignUp: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
    ) }
  ) }
);

type BasicLoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


type BasicLoginQuery = (
  { __typename?: 'Query' }
  & { basicLogin: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
    ) }
  ) }
);

type GetNewAccessTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


type GetNewAccessTokenQuery = (
  { __typename?: 'Query' }
  & { getNewAccessToken: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
    ) }
  ) }
);

type LoginQueryVariables = Exact<{
  identityToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}>;


type LoginQuery = (
  { __typename?: 'Query' }
  & { login: (
    { __typename?: 'TokenDetails' }
    & Pick<TokenDetails, 'accessToken' | 'refreshToken' | 'accessUuid' | 'refreshUuid' | 'atExpires' | 'rtExpires'>
    & { utente: (
      { __typename?: 'Utente' }
      & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
    ) }
  ) }
);

type OttieniAnagrafichePosteQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniAnagrafichePosteQuery = (
  { __typename?: 'Query' }
  & { ottieniAnagrafichePoste?: Maybe<Array<Maybe<(
    { __typename?: 'RaiAnagraficaPoste' }
    & Pick<RaiAnagraficaPoste, 'id' | 'cap' | 'citta' | 'cognome' | 'cognomenome' | 'descrizione' | 'email' | 'emailPreventiva' | 'indirizzo' | 'nome' | 'piano' | 'societa' | 'stanza'>
  )>>> }
);

type OttieniAnagrafichePosteFiltrateQueryVariables = Exact<{
  filtro?: Maybe<Scalars['String']>;
}>;


type OttieniAnagrafichePosteFiltrateQuery = (
  { __typename?: 'Query' }
  & { ottieniAnagrafichePosteFiltrate?: Maybe<Array<Maybe<(
    { __typename?: 'RaiAnagraficaPoste' }
    & Pick<RaiAnagraficaPoste, 'id' | 'cap' | 'citta' | 'cognome' | 'cognomenome' | 'descrizione' | 'email' | 'emailPreventiva' | 'indirizzo' | 'nome' | 'piano' | 'societa' | 'stanza'>
  )>>> }
);

type OttieniArticoliQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniArticoliQuery = (
  { __typename?: 'Query' }
  & { ottieniArticoli?: Maybe<Array<Maybe<(
    { __typename?: 'Articolo' }
    & Pick<Articolo, 'ID' | 'descrizione' | 'barcodes' | 'taglia' | 'note'>
  )>>> }
);

type OttieniArticoliPerMagazzinoQueryVariables = Exact<{
  idMagazzino: Scalars['String'];
}>;


type OttieniArticoliPerMagazzinoQuery = (
  { __typename?: 'Query' }
  & { ottieniArticoliPerMagazzino?: Maybe<Array<Maybe<(
    { __typename?: 'Articolo' }
    & Pick<Articolo, 'ID' | 'descrizione' | 'barcodes' | 'taglia' | 'note'>
  )>>> }
);

type OttieniArticoloQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type OttieniArticoloQuery = (
  { __typename?: 'Query' }
  & { ottieniArticolo?: Maybe<(
    { __typename?: 'Articolo' }
    & Pick<Articolo, 'ID' | 'descrizione' | 'barcodes' | 'taglia' | 'note'>
  )> }
);

type OttieniArticoloPerBarcodeQueryVariables = Exact<{
  barcode: Scalars['String'];
}>;


type OttieniArticoloPerBarcodeQuery = (
  { __typename?: 'Query' }
  & { ottieniArticoloPerBarcode?: Maybe<(
    { __typename?: 'Articolo' }
    & Pick<Articolo, 'ID' | 'descrizione' | 'barcodes' | 'taglia' | 'note'>
  )> }
);

type OttieniBusteRaiQueryVariables = Exact<{
  startDate: Scalars['Int'];
  endDate: Scalars['Int'];
}>;


type OttieniBusteRaiQuery = (
  { __typename?: 'Query' }
  & { ottieniBusteRai?: Maybe<Array<Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )>>> }
);

type OttieniBusteRaiGiornaliereQueryVariables = Exact<{
  ufficioRicezione?: Maybe<Scalars['String']>;
}>;


type OttieniBusteRaiGiornaliereQuery = (
  { __typename?: 'Query' }
  & { ottieniBusteRaiGiornaliere?: Maybe<Array<Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )>>> }
);

type OttieniConsegnaQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type OttieniConsegnaQuery = (
  { __typename?: 'Query' }
  & { ottieniConsegna?: Maybe<(
    { __typename?: 'Consegna' }
    & Pick<Consegna, 'ID' | 'barcode' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'destinatario' | 'firma' | 'data' | 'matricola' | 'operatore'>
  )> }
);

type OttieniConsegnaMassivaQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


type OttieniConsegnaMassivaQuery = (
  { __typename?: 'Query' }
  & { ottieniConsegnaMassiva?: Maybe<(
    { __typename?: 'RaiPosteConsegnaMassiva' }
    & Pick<RaiPosteConsegnaMassiva, 'codiciBuste' | 'firma' | 'id' | 'operatore' | 'operatoreFirmatario' | 'oraCreazione' | 'oraFirma' | 'stato' | 'ufficio'>
  )> }
);

type OttieniConsegneQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniConsegneQuery = (
  { __typename?: 'Query' }
  & { ottieniConsegne?: Maybe<Array<Maybe<(
    { __typename?: 'Consegna' }
    & Pick<Consegna, 'ID' | 'barcode' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'destinatario' | 'firma' | 'data' | 'matricola' | 'operatore'>
  )>>> }
);

type OttieniConsegneMassiveQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniConsegneMassiveQuery = (
  { __typename?: 'Query' }
  & { ottieniConsegneMassive?: Maybe<Array<Maybe<(
    { __typename?: 'RaiPosteConsegnaMassiva' }
    & Pick<RaiPosteConsegnaMassiva, 'codiciBuste' | 'firma' | 'id' | 'operatore' | 'operatoreFirmatario' | 'oraCreazione' | 'oraFirma' | 'stato' | 'ufficio'>
  )>>> }
);

type OttieniConsegnePerDestinatarioQueryVariables = Exact<{
  destinatario: Scalars['String'];
}>;


type OttieniConsegnePerDestinatarioQuery = (
  { __typename?: 'Query' }
  & { ottieniConsegnePerDestinatario?: Maybe<Array<Maybe<(
    { __typename?: 'Consegna' }
    & Pick<Consegna, 'ID' | 'barcode' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'destinatario' | 'firma' | 'data' | 'matricola' | 'operatore'>
  )>>> }
);

type OttieniCsvConsegneQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniCsvConsegneQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ottieniCsvConsegne'>
);

type OttieniCsvConsegneConIntervalloQueryVariables = Exact<{
  start: Scalars['Int'];
  end: Scalars['Int'];
}>;


type OttieniCsvConsegneConIntervalloQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ottieniCsvConsegneConIntervallo'>
);

type OttieniDestiantariConsegnaPerFullTextQueryVariables = Exact<{
  query: Scalars['String'];
}>;


type OttieniDestiantariConsegnaPerFullTextQuery = (
  { __typename?: 'Query' }
  & { ottieniDestiantariConsegnaPerFullText?: Maybe<Array<Maybe<(
    { __typename?: 'DestinatarioConsegna' }
    & Pick<DestinatarioConsegna, 'matricola' | 'destinatario' | 'puntoDiRitiro'>
  )>>> }
);

type OttieniDestinatariConsegnaQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniDestinatariConsegnaQuery = (
  { __typename?: 'Query' }
  & { ottieniDestinatariConsegna?: Maybe<Array<Maybe<(
    { __typename?: 'DestinatarioConsegna' }
    & Pick<DestinatarioConsegna, 'matricola' | 'destinatario' | 'puntoDiRitiro'>
  )>>> }
);

type OttieniDestinatariConsegnaPerMatricolaQueryVariables = Exact<{
  matricola: Scalars['Int'];
}>;


type OttieniDestinatariConsegnaPerMatricolaQuery = (
  { __typename?: 'Query' }
  & { ottieniDestinatariConsegnaPerMatricola?: Maybe<(
    { __typename?: 'DestinatarioConsegna' }
    & Pick<DestinatarioConsegna, 'matricola' | 'destinatario' | 'puntoDiRitiro'>
  )> }
);

type OttieniDestinatariConsegnaPerNomeQueryVariables = Exact<{
  nome: Scalars['String'];
  cognome: Scalars['String'];
}>;


type OttieniDestinatariConsegnaPerNomeQuery = (
  { __typename?: 'Query' }
  & { ottieniDestinatariConsegnaPerNome?: Maybe<Array<Maybe<(
    { __typename?: 'DestinatarioConsegna' }
    & Pick<DestinatarioConsegna, 'matricola' | 'destinatario' | 'puntoDiRitiro'>
  )>>> }
);

type OttieniFirmaBustaQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type OttieniFirmaBustaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ottieniFirmaBusta'>
);

type OttieniFirmaConsegnaQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type OttieniFirmaConsegnaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ottieniFirmaConsegna'>
);

type OttieniFotoBustaQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type OttieniFotoBustaQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ottieniFotoBusta'>
);

type OttieniMagazziniQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniMagazziniQuery = (
  { __typename?: 'Query' }
  & { ottieniMagazzini?: Maybe<Array<Maybe<(
    { __typename?: 'Magazzino' }
    & Pick<Magazzino, 'ID' | 'descrizione' | 'idPadre' | 'descrizionePadre'>
  )>>> }
);

type OttieniMagazzinoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type OttieniMagazzinoQuery = (
  { __typename?: 'Query' }
  & { ottieniMagazzino?: Maybe<(
    { __typename?: 'Magazzino' }
    & Pick<Magazzino, 'ID' | 'descrizione' | 'idPadre' | 'descrizionePadre'>
  )> }
);

type OttieniMovimentiQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniMovimentiQuery = (
  { __typename?: 'Query' }
  & { ottieniMovimenti?: Maybe<Array<Maybe<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'quantita' | 'tipo' | 'idRiferimento' | 'data'>
  )>>> }
);

type OttieniMovimentiPerArticoloQueryVariables = Exact<{
  idArticolo: Scalars['String'];
}>;


type OttieniMovimentiPerArticoloQuery = (
  { __typename?: 'Query' }
  & { ottieniMovimentiPerArticolo?: Maybe<Array<Maybe<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'quantita' | 'tipo' | 'idRiferimento' | 'data'>
  )>>> }
);

type OttieniMovimentiPerMagazzinoQueryVariables = Exact<{
  idMagazzino: Scalars['String'];
}>;


type OttieniMovimentiPerMagazzinoQuery = (
  { __typename?: 'Query' }
  & { ottieniMovimentiPerMagazzino?: Maybe<Array<Maybe<(
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'quantita' | 'tipo' | 'idRiferimento' | 'data'>
  )>>> }
);

type OttieniMovimentoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type OttieniMovimentoQuery = (
  { __typename?: 'Query' }
  & { ottieniMovimento: (
    { __typename?: 'Movimento' }
    & Pick<Movimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'idMagazzino' | 'descrizioneMagazzino' | 'quantita' | 'tipo' | 'idRiferimento' | 'data'>
  ) }
);

type OttieniTotaleConsegneMagazzinoQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniTotaleConsegneMagazzinoQuery = (
  { __typename?: 'Query' }
  & { ottieniTotaleConsegneMagazzino?: Maybe<Array<Maybe<(
    { __typename?: 'TotaleConsegneMagazzino' }
    & Pick<TotaleConsegneMagazzino, 'magazzino' | 'articolo' | 'totale'>
  )>>> }
);

type OttieniTotaleConsegneOperatoriQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniTotaleConsegneOperatoriQuery = (
  { __typename?: 'Query' }
  & { ottieniTotaleConsegneOperatori?: Maybe<Array<Maybe<(
    { __typename?: 'TotaleConsegneOperatori' }
    & Pick<TotaleConsegneOperatori, 'operatore' | 'articolo' | 'totale'>
  )>>> }
);

type OttieniTotaleMagazziniQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniTotaleMagazziniQuery = (
  { __typename?: 'Query' }
  & { ottieniTotaleMagazzini?: Maybe<Array<Maybe<(
    { __typename?: 'TotaleArticoloMagazzino' }
    & Pick<TotaleArticoloMagazzino, 'idArticolo' | 'descrizioneArticolo' | 'quantita' | 'idMagazzino' | 'descrizioneMagazzino'>
  )>>> }
);

type OttieniTotaleMagazzinoQueryVariables = Exact<{
  idMagazzino: Scalars['String'];
}>;


type OttieniTotaleMagazzinoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ottieniTotaleMagazzino'>
);

type OttieniTotaleMagazzinoPerArticoloQueryVariables = Exact<{
  idMagazzino: Scalars['String'];
  idArticolo: Scalars['String'];
}>;


type OttieniTotaleMagazzinoPerArticoloQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ottieniTotaleMagazzinoPerArticolo'>
);

type OttieniTrasferimentiQueryVariables = Exact<{ [key: string]: never; }>;


type OttieniTrasferimentiQuery = (
  { __typename?: 'Query' }
  & { ottieniTrasferimenti?: Maybe<Array<Maybe<(
    { __typename?: 'Trasferimento' }
    & Pick<Trasferimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'quantita' | 'idMagazzinoDiArrivo' | 'idMagazzinoDiPartenza' | 'desMagazzinoArrivo' | 'desMagazzinoPartenza' | 'data'>
  )>>> }
);

type OttieniTrasferimentoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type OttieniTrasferimentoQuery = (
  { __typename?: 'Query' }
  & { ottieniTrasferimento?: Maybe<(
    { __typename?: 'Trasferimento' }
    & Pick<Trasferimento, 'ID' | 'idArticolo' | 'descrizioneArticolo' | 'quantita' | 'idMagazzinoDiArrivo' | 'idMagazzinoDiPartenza' | 'desMagazzinoArrivo' | 'desMagazzinoPartenza' | 'data'>
  )> }
);

type OttieniTrasferimentoUfficioQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


type OttieniTrasferimentoUfficioQuery = (
  { __typename?: 'Query' }
  & { ottieniTrasferimentoUfficio?: Maybe<(
    { __typename?: 'RaiPosteTrasferimentoUfficio' }
    & Pick<RaiPosteTrasferimentoUfficio, 'codiciBuste' | 'firma' | 'id' | 'operatore' | 'operatoreFirmatario' | 'oraCreazione' | 'oraFirma' | 'stato' | 'ufficioDestinatario' | 'ufficioMittente'>
  )> }
);

type PingQueryVariables = Exact<{ [key: string]: never; }>;


type PingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ping'>
);

type QuantitaArticoliConsegnatiPerMagazzinoQueryVariables = Exact<{
  idMagazzino: Scalars['String'];
}>;


type QuantitaArticoliConsegnatiPerMagazzinoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'quantitaArticoliConsegnatiPerMagazzino'>
);

type RistampaEtichettaQueryVariables = Exact<{
  id: Scalars['String'];
}>;


type RistampaEtichettaQuery = (
  { __typename?: 'Query' }
  & { ristampaEtichetta?: Maybe<(
    { __typename?: 'RaiBusta' }
    & Pick<RaiBusta, 'barcode' | 'destinatario' | 'emailPreventiva' | 'firma' | 'foto' | 'id' | 'mittente' | 'operatoreLoggatoFirma' | 'operatoreLoggatoScansione' | 'operatoreUltimaModifica' | 'oraConsegna' | 'oraFirma' | 'oraRicezione' | 'orariTentativiDiConsegna' | 'stato' | 'tentativiDiConsegnaEffetuati' | 'trasferito' | 'ufficioRicezione' | 'ufficioTrasferimento' | 'normalized'>
  )> }
);

type ScaricaReportBusteQueryVariables = Exact<{
  idBuste: Array<Scalars['String']>;
}>;


type ScaricaReportBusteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'scaricaReportBuste'>
);

type ScaricaReportBusteCompattoQueryVariables = Exact<{
  idBuste: Array<Scalars['String']>;
}>;


type ScaricaReportBusteCompattoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'scaricaReportBusteCompatto'>
);

type UtenteLoggatoQueryVariables = Exact<{ [key: string]: never; }>;


type UtenteLoggatoQuery = (
  { __typename?: 'Query' }
  & { utenteLoggato: (
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
  ) }
);

type UtentiQueryVariables = Exact<{ [key: string]: never; }>;


type UtentiQuery = (
  { __typename?: 'Query' }
  & { utenti?: Maybe<Array<Maybe<(
    { __typename?: 'Utente' }
    & Pick<Utente, 'nome' | 'cognome' | 'email' | 'ruolo' | 'avatar' | 'dataNascita' | 'provider' | 'dataRegistrazione' | 'createdAt' | 'tags' | 'idMagazzino' | 'descrizioneMagazzino'>
  )>>> }
);


 const AggiornaAnagraficaPosteDocument = gql`
    mutation aggiornaAnagraficaPoste($societa: String, $email: String, $cap: String, $nome: String, $cognome: String, $cognomenome: String, $piano: String, $stanza: String, $descrizione: String, $emailPreventiva: Boolean, $id: String!, $indirizzo: String, $citta: String) {
  aggiornaAnagraficaPoste(societa: $societa, email: $email, cap: $cap, nome: $nome, cognome: $cognome, cognomenome: $cognomenome, piano: $piano, stanza: $stanza, descrizione: $descrizione, emailPreventiva: $emailPreventiva, id: $id, indirizzo: $indirizzo, citta: $citta) {
    id
    cap
    citta
    cognome
    cognomenome
    descrizione
    email
    emailPreventiva
    indirizzo
    nome
    piano
    societa
    stanza
  }
}
    `;
 const AggiornaArticoloDocument = gql`
    mutation aggiornaArticolo($id: String!, $input: NuovoArticolo) {
  aggiornaArticolo(id: $id, input: $input) {
    ID
    descrizione
    barcodes
    taglia
    note
  }
}
    `;
 const AggiornaMagazzinoDocument = gql`
    mutation aggiornaMagazzino($id: String!, $descrizione: String, $idPadre: String) {
  aggiornaMagazzino(id: $id, descrizione: $descrizione, idPadre: $idPadre) {
    ID
    descrizione
    idPadre
    descrizionePadre
  }
}
    `;
 const AggiornaMovimentoDocument = gql`
    mutation aggiornaMovimento($id: String!, $idArticolo: String!, $idMagazzino: String!, $tipo: String!, $quantita: Int!, $idRiferimento: String!) {
  aggiornaMovimento(id: $id, idArticolo: $idArticolo, idMagazzino: $idMagazzino, tipo: $tipo, quantita: $quantita, idRiferimento: $idRiferimento) {
    ID
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    quantita
    tipo
    idRiferimento
    data
  }
}
    `;
 const AggiornaTrasferimentoDocument = gql`
    mutation aggiornaTrasferimento($id: String!, $input: InputTrasferimento) {
  aggiornaTrasferimento(id: $id, input: $input) {
    ID
    idArticolo
    descrizioneArticolo
    quantita
    idMagazzinoDiArrivo
    idMagazzinoDiPartenza
    desMagazzinoArrivo
    desMagazzinoPartenza
    data
  }
}
    `;
 const AggiornaUtenteDocument = gql`
    mutation aggiornaUtente($email: String!, $tags: [String!]!, $idMagazzino: String) {
  aggiornaUtente(email: $email, tags: $tags, idMagazzino: $idMagazzino) {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    idMagazzino
    descrizioneMagazzino
  }
}
    `;
 const BustaAltraCompetenzaDocument = gql`
    mutation bustaAltraCompetenza($id: String) {
  bustaAltraCompetenza(id: $id) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const ConsegnaFallitaDocument = gql`
    mutation consegnaFallita($id: String) {
  consegnaFallita(id: $id) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const CreaAnagraficaPosteDocument = gql`
    mutation creaAnagraficaPoste($email: String, $cap: String, $piano: String, $nome: String, $cognome: String, $indirizzo: String, $citta: String, $stanza: String, $emailPreventiva: Boolean, $descrizione: String, $cognomenome: String, $societa: String) {
  creaAnagraficaPoste(email: $email, cap: $cap, piano: $piano, nome: $nome, cognome: $cognome, indirizzo: $indirizzo, citta: $citta, stanza: $stanza, emailPreventiva: $emailPreventiva, descrizione: $descrizione, cognomenome: $cognomenome, societa: $societa) {
    id
    cap
    citta
    cognome
    cognomenome
    descrizione
    email
    emailPreventiva
    indirizzo
    nome
    piano
    societa
    stanza
  }
}
    `;
 const CreaBustaRaiDocument = gql`
    mutation creaBustaRai($mittente: String, $destinatario: String, $barcode: String, $foto: String, $emailPreventiva: Boolean, $ufficioRicezione: String) {
  creaBustaRai(mittente: $mittente, destinatario: $destinatario, barcode: $barcode, foto: $foto, emailPreventiva: $emailPreventiva, ufficioRicezione: $ufficioRicezione) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const CreaConsegnaMassivaPosteDocument = gql`
    mutation creaConsegnaMassivaPoste($codiciBuste: [String], $operatore: String, $ufficio: String) {
  creaConsegnaMassivaPoste(codiciBuste: $codiciBuste, operatore: $operatore, ufficio: $ufficio) {
    codiciBuste
    firma
    id
    operatore
    operatoreFirmatario
    oraCreazione
    oraFirma
    stato
    ufficio
  }
}
    `;
 const CreaTrasferimentoUfficioPosteDocument = gql`
    mutation creaTrasferimentoUfficioPoste($codiciBuste: [String], $ufficioMittente: String, $ufficioDestinatario: String, $operatore: String) {
  creaTrasferimentoUfficioPoste(codiciBuste: $codiciBuste, ufficioMittente: $ufficioMittente, ufficioDestinatario: $ufficioDestinatario, operatore: $operatore) {
    codiciBuste
    firma
    id
    operatore
    operatoreFirmatario
    oraCreazione
    oraFirma
    stato
    ufficioDestinatario
    ufficioMittente
  }
}
    `;
 const CreaUtenteDocument = gql`
    mutation creaUtente($utente: InputUtenteConPassword) {
  creaUtente(utente: $utente) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      idMagazzino
      descrizioneMagazzino
    }
  }
}
    `;
 const EliminaAnagraficaPosteDocument = gql`
    mutation eliminaAnagraficaPoste($id: String!) {
  eliminaAnagraficaPoste(id: $id) {
    id
    cap
    citta
    cognome
    cognomenome
    descrizione
    email
    emailPreventiva
    indirizzo
    nome
    piano
    societa
    stanza
  }
}
    `;
 const EliminaArticoloDocument = gql`
    mutation eliminaArticolo($id: String!) {
  eliminaArticolo(id: $id) {
    ID
    descrizione
    barcodes
    taglia
    note
  }
}
    `;
 const EliminaBarcodeDocument = gql`
    mutation eliminaBarcode($id: String!, $barcode: String!) {
  eliminaBarcode(id: $id, barcode: $barcode) {
    ID
    descrizione
    barcodes
    taglia
    note
  }
}
    `;
 const EliminaBustaDocument = gql`
    mutation eliminaBusta($id: String) {
  eliminaBusta(id: $id) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const EliminaConsegnaDocument = gql`
    mutation eliminaConsegna($id: String!) {
  eliminaConsegna(id: $id) {
    ID
    barcode
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    destinatario
    firma
    data
    matricola
    operatore
  }
}
    `;
 const EliminaMagazzinoDocument = gql`
    mutation eliminaMagazzino($id: String!) {
  eliminaMagazzino(id: $id) {
    ID
    descrizione
    idPadre
    descrizionePadre
  }
}
    `;
 const EliminaMovimentoDocument = gql`
    mutation eliminaMovimento($id: String!) {
  eliminaMovimento(id: $id) {
    ID
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    quantita
    tipo
    idRiferimento
    data
  }
}
    `;
 const EliminaTrasferimentoDocument = gql`
    mutation eliminaTrasferimento($id: String!) {
  eliminaTrasferimento(id: $id) {
    ID
    idArticolo
    descrizioneArticolo
    quantita
    idMagazzinoDiArrivo
    idMagazzinoDiPartenza
    desMagazzinoArrivo
    desMagazzinoPartenza
    data
  }
}
    `;
 const EliminaUtenteDocument = gql`
    mutation eliminaUtente($email: String!) {
  eliminaUtente(email: $email) {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    idMagazzino
    descrizioneMagazzino
  }
}
    `;
 const FirmaBustaDocument = gql`
    mutation firmaBusta($id: String, $firma: String) {
  firmaBusta(id: $id, firma: $firma) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const FirmaConsegnaMassivaDocument = gql`
    mutation firmaConsegnaMassiva($id: String, $firma: String, $operatore: String) {
  firmaConsegnaMassiva(id: $id, firma: $firma, operatore: $operatore) {
    codiciBuste
    firma
    id
    operatore
    operatoreFirmatario
    oraCreazione
    oraFirma
    stato
    ufficio
  }
}
    `;
 const FirmaDifferitaDocument = gql`
    mutation firmaDifferita($token: String!, $firma: String!) {
  firmaDifferita(token: $token, firma: $firma) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const FirmaTrasferimentoUfficioDocument = gql`
    mutation firmaTrasferimentoUfficio($id: String, $firma: String, $operatore: String) {
  firmaTrasferimentoUfficio(id: $id, firma: $firma, operatore: $operatore) {
    codiciBuste
    firma
    id
    operatore
    operatoreFirmatario
    oraCreazione
    oraFirma
    stato
    ufficioDestinatario
    ufficioMittente
  }
}
    `;
 const InserisciArticoloDocument = gql`
    mutation inserisciArticolo($input: NuovoArticolo) {
  inserisciArticolo(input: $input) {
    ID
    descrizione
    barcodes
    taglia
    note
  }
}
    `;
 const InserisciBarcodeDocument = gql`
    mutation inserisciBarcode($id: String!, $barcode: String!) {
  inserisciBarcode(id: $id, barcode: $barcode) {
    ID
    descrizione
    barcodes
    taglia
    note
  }
}
    `;
 const InserisciConsegnaDocument = gql`
    mutation inserisciConsegna($input: InputConsegna!) {
  inserisciConsegna(input: $input) {
    ID
    barcode
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    destinatario
    firma
    data
    matricola
    operatore
  }
}
    `;
 const InserisciDestinatarioConsegnaDocument = gql`
    mutation inserisciDestinatarioConsegna($matricola: Int!, $nome: String!, $cognome: String!, $ritiro: String!) {
  inserisciDestinatarioConsegna(matricola: $matricola, nome: $nome, cognome: $cognome, ritiro: $ritiro) {
    matricola
    destinatario
    puntoDiRitiro
  }
}
    `;
 const InserisciMagazzinoDocument = gql`
    mutation inserisciMagazzino($descrizione: String!, $idPadre: String) {
  inserisciMagazzino(descrizione: $descrizione, idPadre: $idPadre) {
    ID
    descrizione
    idPadre
    descrizionePadre
  }
}
    `;
 const InserisciMovimentoDocument = gql`
    mutation inserisciMovimento($idArticolo: String!, $idMagazzino: String!, $tipo: String!, $quantita: Int!, $idRiferimento: String!) {
  inserisciMovimento(idArticolo: $idArticolo, idMagazzino: $idMagazzino, tipo: $tipo, quantita: $quantita, idRiferimento: $idRiferimento) {
    ID
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    quantita
    tipo
    idRiferimento
    data
  }
}
    `;
 const InserisciTrasferimentoDocument = gql`
    mutation inserisciTrasferimento($input: InputTrasferimento) {
  inserisciTrasferimento(input: $input) {
    ID
    idArticolo
    descrizioneArticolo
    quantita
    idMagazzinoDiArrivo
    idMagazzinoDiPartenza
    desMagazzinoArrivo
    desMagazzinoPartenza
    data
  }
}
    `;
 const ModificaBustaDocument = gql`
    mutation modificaBusta($id: String!, $mittente: String, $destinatario: String) {
  modificaBusta(id: $id, mittente: $mittente, destinatario: $destinatario) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const PingMutationDocument = gql`
    mutation pingMutation {
  pingMutation
}
    `;
 const RichiestaFirmaDifferitaDocument = gql`
    mutation richiestaFirmaDifferita($id: String!, $tipologia: String!, $contatto: String!) {
  richiestaFirmaDifferita(id: $id, tipologia: $tipologia, contatto: $contatto) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const RimuoviDestinatarioConsegnaDocument = gql`
    mutation rimuoviDestinatarioConsegna($matricola: Int!) {
  rimuoviDestinatarioConsegna(matricola: $matricola) {
    matricola
    destinatario
    puntoDiRitiro
  }
}
    `;
 const RimuoviMagazzinoDaUtenteDocument = gql`
    mutation rimuoviMagazzinoDaUtente($email: String!) {
  rimuoviMagazzinoDaUtente(email: $email) {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    idMagazzino
    descrizioneMagazzino
  }
}
    `;
 const OttieniBustaDocument = gql`
    query OttieniBusta($id: String!) {
  OttieniBusta(id: $id) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const AppleSignInDocument = gql`
    query appleSignIn($identityToken: String!) {
  appleSignIn(identityToken: $identityToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      idMagazzino
      descrizioneMagazzino
    }
  }
}
    `;
 const AppleSignUpDocument = gql`
    query appleSignUp($identityToken: String!, $firstName: String!, $lastName: String!) {
  appleSignUp(identityToken: $identityToken, firstName: $firstName, lastName: $lastName) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      idMagazzino
      descrizioneMagazzino
    }
  }
}
    `;
 const BasicLoginDocument = gql`
    query basicLogin($username: String!, $password: String!) {
  basicLogin(username: $username, password: $password) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      idMagazzino
      descrizioneMagazzino
    }
  }
}
    `;
 const GetNewAccessTokenDocument = gql`
    query getNewAccessToken($refreshToken: String!) {
  getNewAccessToken(refreshToken: $refreshToken) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      idMagazzino
      descrizioneMagazzino
    }
  }
}
    `;
 const LoginDocument = gql`
    query login($identityToken: String!, $firstName: String!, $lastName: String!) {
  login(identityToken: $identityToken, firstName: $firstName, lastName: $lastName) {
    accessToken
    refreshToken
    accessUuid
    refreshUuid
    atExpires
    rtExpires
    utente {
      nome
      cognome
      email
      ruolo
      avatar
      dataNascita
      provider
      dataRegistrazione
      createdAt
      tags
      idMagazzino
      descrizioneMagazzino
    }
  }
}
    `;
 const OttieniAnagrafichePosteDocument = gql`
    query ottieniAnagrafichePoste {
  ottieniAnagrafichePoste {
    id
    cap
    citta
    cognome
    cognomenome
    descrizione
    email
    emailPreventiva
    indirizzo
    nome
    piano
    societa
    stanza
  }
}
    `;
 const OttieniAnagrafichePosteFiltrateDocument = gql`
    query ottieniAnagrafichePosteFiltrate($filtro: String) {
  ottieniAnagrafichePosteFiltrate(filtro: $filtro) {
    id
    cap
    citta
    cognome
    cognomenome
    descrizione
    email
    emailPreventiva
    indirizzo
    nome
    piano
    societa
    stanza
  }
}
    `;
 const OttieniArticoliDocument = gql`
    query ottieniArticoli {
  ottieniArticoli {
    ID
    descrizione
    barcodes
    taglia
    note
  }
}
    `;
 const OttieniArticoliPerMagazzinoDocument = gql`
    query ottieniArticoliPerMagazzino($idMagazzino: String!) {
  ottieniArticoliPerMagazzino(idMagazzino: $idMagazzino) {
    ID
    descrizione
    barcodes
    taglia
    note
  }
}
    `;
 const OttieniArticoloDocument = gql`
    query ottieniArticolo($id: String!) {
  ottieniArticolo(id: $id) {
    ID
    descrizione
    barcodes
    taglia
    note
  }
}
    `;
 const OttieniArticoloPerBarcodeDocument = gql`
    query ottieniArticoloPerBarcode($barcode: String!) {
  ottieniArticoloPerBarcode(barcode: $barcode) {
    ID
    descrizione
    barcodes
    taglia
    note
  }
}
    `;
 const OttieniBusteRaiDocument = gql`
    query ottieniBusteRai($startDate: Int!, $endDate: Int!) {
  ottieniBusteRai(startDate: $startDate, endDate: $endDate) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const OttieniBusteRaiGiornaliereDocument = gql`
    query ottieniBusteRaiGiornaliere($ufficioRicezione: String) {
  ottieniBusteRaiGiornaliere(ufficioRicezione: $ufficioRicezione) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const OttieniConsegnaDocument = gql`
    query ottieniConsegna($id: String!) {
  ottieniConsegna(id: $id) {
    ID
    barcode
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    destinatario
    firma
    data
    matricola
    operatore
  }
}
    `;
 const OttieniConsegnaMassivaDocument = gql`
    query ottieniConsegnaMassiva($id: String) {
  ottieniConsegnaMassiva(id: $id) {
    codiciBuste
    firma
    id
    operatore
    operatoreFirmatario
    oraCreazione
    oraFirma
    stato
    ufficio
  }
}
    `;
 const OttieniConsegneDocument = gql`
    query ottieniConsegne {
  ottieniConsegne {
    ID
    barcode
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    destinatario
    firma
    data
    matricola
    operatore
  }
}
    `;
 const OttieniConsegneMassiveDocument = gql`
    query ottieniConsegneMassive {
  ottieniConsegneMassive {
    codiciBuste
    firma
    id
    operatore
    operatoreFirmatario
    oraCreazione
    oraFirma
    stato
    ufficio
  }
}
    `;
 const OttieniConsegnePerDestinatarioDocument = gql`
    query ottieniConsegnePerDestinatario($destinatario: String!) {
  ottieniConsegnePerDestinatario(destinatario: $destinatario) {
    ID
    barcode
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    destinatario
    firma
    data
    matricola
    operatore
  }
}
    `;
 const OttieniCsvConsegneDocument = gql`
    query ottieniCsvConsegne {
  ottieniCsvConsegne
}
    `;
 const OttieniCsvConsegneConIntervalloDocument = gql`
    query ottieniCsvConsegneConIntervallo($start: Int!, $end: Int!) {
  ottieniCsvConsegneConIntervallo(start: $start, end: $end)
}
    `;
 const OttieniDestiantariConsegnaPerFullTextDocument = gql`
    query ottieniDestiantariConsegnaPerFullText($query: String!) {
  ottieniDestiantariConsegnaPerFullText(query: $query) {
    matricola
    destinatario
    puntoDiRitiro
  }
}
    `;
 const OttieniDestinatariConsegnaDocument = gql`
    query ottieniDestinatariConsegna {
  ottieniDestinatariConsegna {
    matricola
    destinatario
    puntoDiRitiro
  }
}
    `;
 const OttieniDestinatariConsegnaPerMatricolaDocument = gql`
    query ottieniDestinatariConsegnaPerMatricola($matricola: Int!) {
  ottieniDestinatariConsegnaPerMatricola(matricola: $matricola) {
    matricola
    destinatario
    puntoDiRitiro
  }
}
    `;
 const OttieniDestinatariConsegnaPerNomeDocument = gql`
    query ottieniDestinatariConsegnaPerNome($nome: String!, $cognome: String!) {
  ottieniDestinatariConsegnaPerNome(nome: $nome, cognome: $cognome) {
    matricola
    destinatario
    puntoDiRitiro
  }
}
    `;
 const OttieniFirmaBustaDocument = gql`
    query ottieniFirmaBusta($id: String!) {
  ottieniFirmaBusta(id: $id)
}
    `;
 const OttieniFirmaConsegnaDocument = gql`
    query ottieniFirmaConsegna($id: String!) {
  ottieniFirmaConsegna(id: $id)
}
    `;
 const OttieniFotoBustaDocument = gql`
    query ottieniFotoBusta($id: String!) {
  ottieniFotoBusta(id: $id)
}
    `;
 const OttieniMagazziniDocument = gql`
    query ottieniMagazzini {
  ottieniMagazzini {
    ID
    descrizione
    idPadre
    descrizionePadre
  }
}
    `;
 const OttieniMagazzinoDocument = gql`
    query ottieniMagazzino($id: String!) {
  ottieniMagazzino(id: $id) {
    ID
    descrizione
    idPadre
    descrizionePadre
  }
}
    `;
 const OttieniMovimentiDocument = gql`
    query ottieniMovimenti {
  ottieniMovimenti {
    ID
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    quantita
    tipo
    idRiferimento
    data
  }
}
    `;
 const OttieniMovimentiPerArticoloDocument = gql`
    query ottieniMovimentiPerArticolo($idArticolo: String!) {
  ottieniMovimentiPerArticolo(idArticolo: $idArticolo) {
    ID
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    quantita
    tipo
    idRiferimento
    data
  }
}
    `;
 const OttieniMovimentiPerMagazzinoDocument = gql`
    query ottieniMovimentiPerMagazzino($idMagazzino: String!) {
  ottieniMovimentiPerMagazzino(idMagazzino: $idMagazzino) {
    ID
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    quantita
    tipo
    idRiferimento
    data
  }
}
    `;
 const OttieniMovimentoDocument = gql`
    query ottieniMovimento($id: String!) {
  ottieniMovimento(id: $id) {
    ID
    idArticolo
    descrizioneArticolo
    idMagazzino
    descrizioneMagazzino
    quantita
    tipo
    idRiferimento
    data
  }
}
    `;
 const OttieniTotaleConsegneMagazzinoDocument = gql`
    query ottieniTotaleConsegneMagazzino {
  ottieniTotaleConsegneMagazzino {
    magazzino
    articolo
    totale
  }
}
    `;
 const OttieniTotaleConsegneOperatoriDocument = gql`
    query ottieniTotaleConsegneOperatori {
  ottieniTotaleConsegneOperatori {
    operatore
    articolo
    totale
  }
}
    `;
 const OttieniTotaleMagazziniDocument = gql`
    query ottieniTotaleMagazzini {
  ottieniTotaleMagazzini {
    idArticolo
    descrizioneArticolo
    quantita
    idMagazzino
    descrizioneMagazzino
  }
}
    `;
 const OttieniTotaleMagazzinoDocument = gql`
    query ottieniTotaleMagazzino($idMagazzino: String!) {
  ottieniTotaleMagazzino(idMagazzino: $idMagazzino)
}
    `;
 const OttieniTotaleMagazzinoPerArticoloDocument = gql`
    query ottieniTotaleMagazzinoPerArticolo($idMagazzino: String!, $idArticolo: String!) {
  ottieniTotaleMagazzinoPerArticolo(idMagazzino: $idMagazzino, idArticolo: $idArticolo)
}
    `;
 const OttieniTrasferimentiDocument = gql`
    query ottieniTrasferimenti {
  ottieniTrasferimenti {
    ID
    idArticolo
    descrizioneArticolo
    quantita
    idMagazzinoDiArrivo
    idMagazzinoDiPartenza
    desMagazzinoArrivo
    desMagazzinoPartenza
    data
  }
}
    `;
 const OttieniTrasferimentoDocument = gql`
    query ottieniTrasferimento($id: String!) {
  ottieniTrasferimento(id: $id) {
    ID
    idArticolo
    descrizioneArticolo
    quantita
    idMagazzinoDiArrivo
    idMagazzinoDiPartenza
    desMagazzinoArrivo
    desMagazzinoPartenza
    data
  }
}
    `;
 const OttieniTrasferimentoUfficioDocument = gql`
    query ottieniTrasferimentoUfficio($id: String) {
  ottieniTrasferimentoUfficio(id: $id) {
    codiciBuste
    firma
    id
    operatore
    operatoreFirmatario
    oraCreazione
    oraFirma
    stato
    ufficioDestinatario
    ufficioMittente
  }
}
    `;
 const PingDocument = gql`
    query ping {
  ping
}
    `;
 const QuantitaArticoliConsegnatiPerMagazzinoDocument = gql`
    query quantitaArticoliConsegnatiPerMagazzino($idMagazzino: String!) {
  quantitaArticoliConsegnatiPerMagazzino(idMagazzino: $idMagazzino)
}
    `;
 const RistampaEtichettaDocument = gql`
    query ristampaEtichetta($id: String!) {
  ristampaEtichetta(id: $id) {
    barcode
    destinatario
    emailPreventiva
    firma
    foto
    id
    mittente
    operatoreLoggatoFirma
    operatoreLoggatoScansione
    operatoreUltimaModifica
    oraConsegna
    oraFirma
    oraRicezione
    orariTentativiDiConsegna
    stato
    tentativiDiConsegnaEffetuati
    trasferito
    ufficioRicezione
    ufficioTrasferimento
    normalized
  }
}
    `;
 const ScaricaReportBusteDocument = gql`
    query scaricaReportBuste($idBuste: [String!]!) {
  scaricaReportBuste(idBuste: $idBuste)
}
    `;
 const ScaricaReportBusteCompattoDocument = gql`
    query scaricaReportBusteCompatto($idBuste: [String!]!) {
  scaricaReportBusteCompatto(idBuste: $idBuste)
}
    `;
 const UtenteLoggatoDocument = gql`
    query utenteLoggato {
  utenteLoggato {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    idMagazzino
    descrizioneMagazzino
  }
}
    `;
 const UtentiDocument = gql`
    query utenti {
  utenti {
    nome
    cognome
    email
    ruolo
    avatar
    dataNascita
    provider
    dataRegistrazione
    createdAt
    tags
    idMagazzino
    descrizioneMagazzino
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    aggiornaAnagraficaPoste(variables: AggiornaAnagraficaPosteMutationVariables): Promise<AggiornaAnagraficaPosteMutation> {
      return withWrapper(() => client.request<AggiornaAnagraficaPosteMutation>(print(AggiornaAnagraficaPosteDocument), variables));
    },
    aggiornaArticolo(variables: AggiornaArticoloMutationVariables): Promise<AggiornaArticoloMutation> {
      return withWrapper(() => client.request<AggiornaArticoloMutation>(print(AggiornaArticoloDocument), variables));
    },
    aggiornaMagazzino(variables: AggiornaMagazzinoMutationVariables): Promise<AggiornaMagazzinoMutation> {
      return withWrapper(() => client.request<AggiornaMagazzinoMutation>(print(AggiornaMagazzinoDocument), variables));
    },
    aggiornaMovimento(variables: AggiornaMovimentoMutationVariables): Promise<AggiornaMovimentoMutation> {
      return withWrapper(() => client.request<AggiornaMovimentoMutation>(print(AggiornaMovimentoDocument), variables));
    },
    aggiornaTrasferimento(variables: AggiornaTrasferimentoMutationVariables): Promise<AggiornaTrasferimentoMutation> {
      return withWrapper(() => client.request<AggiornaTrasferimentoMutation>(print(AggiornaTrasferimentoDocument), variables));
    },
    aggiornaUtente(variables: AggiornaUtenteMutationVariables): Promise<AggiornaUtenteMutation> {
      return withWrapper(() => client.request<AggiornaUtenteMutation>(print(AggiornaUtenteDocument), variables));
    },
    bustaAltraCompetenza(variables?: BustaAltraCompetenzaMutationVariables): Promise<BustaAltraCompetenzaMutation> {
      return withWrapper(() => client.request<BustaAltraCompetenzaMutation>(print(BustaAltraCompetenzaDocument), variables));
    },
    consegnaFallita(variables?: ConsegnaFallitaMutationVariables): Promise<ConsegnaFallitaMutation> {
      return withWrapper(() => client.request<ConsegnaFallitaMutation>(print(ConsegnaFallitaDocument), variables));
    },
    creaAnagraficaPoste(variables?: CreaAnagraficaPosteMutationVariables): Promise<CreaAnagraficaPosteMutation> {
      return withWrapper(() => client.request<CreaAnagraficaPosteMutation>(print(CreaAnagraficaPosteDocument), variables));
    },
    creaBustaRai(variables?: CreaBustaRaiMutationVariables): Promise<CreaBustaRaiMutation> {
      return withWrapper(() => client.request<CreaBustaRaiMutation>(print(CreaBustaRaiDocument), variables));
    },
    creaConsegnaMassivaPoste(variables?: CreaConsegnaMassivaPosteMutationVariables): Promise<CreaConsegnaMassivaPosteMutation> {
      return withWrapper(() => client.request<CreaConsegnaMassivaPosteMutation>(print(CreaConsegnaMassivaPosteDocument), variables));
    },
    creaTrasferimentoUfficioPoste(variables?: CreaTrasferimentoUfficioPosteMutationVariables): Promise<CreaTrasferimentoUfficioPosteMutation> {
      return withWrapper(() => client.request<CreaTrasferimentoUfficioPosteMutation>(print(CreaTrasferimentoUfficioPosteDocument), variables));
    },
    creaUtente(variables?: CreaUtenteMutationVariables): Promise<CreaUtenteMutation> {
      return withWrapper(() => client.request<CreaUtenteMutation>(print(CreaUtenteDocument), variables));
    },
    eliminaAnagraficaPoste(variables: EliminaAnagraficaPosteMutationVariables): Promise<EliminaAnagraficaPosteMutation> {
      return withWrapper(() => client.request<EliminaAnagraficaPosteMutation>(print(EliminaAnagraficaPosteDocument), variables));
    },
    eliminaArticolo(variables: EliminaArticoloMutationVariables): Promise<EliminaArticoloMutation> {
      return withWrapper(() => client.request<EliminaArticoloMutation>(print(EliminaArticoloDocument), variables));
    },
    eliminaBarcode(variables: EliminaBarcodeMutationVariables): Promise<EliminaBarcodeMutation> {
      return withWrapper(() => client.request<EliminaBarcodeMutation>(print(EliminaBarcodeDocument), variables));
    },
    eliminaBusta(variables?: EliminaBustaMutationVariables): Promise<EliminaBustaMutation> {
      return withWrapper(() => client.request<EliminaBustaMutation>(print(EliminaBustaDocument), variables));
    },
    eliminaConsegna(variables: EliminaConsegnaMutationVariables): Promise<EliminaConsegnaMutation> {
      return withWrapper(() => client.request<EliminaConsegnaMutation>(print(EliminaConsegnaDocument), variables));
    },
    eliminaMagazzino(variables: EliminaMagazzinoMutationVariables): Promise<EliminaMagazzinoMutation> {
      return withWrapper(() => client.request<EliminaMagazzinoMutation>(print(EliminaMagazzinoDocument), variables));
    },
    eliminaMovimento(variables: EliminaMovimentoMutationVariables): Promise<EliminaMovimentoMutation> {
      return withWrapper(() => client.request<EliminaMovimentoMutation>(print(EliminaMovimentoDocument), variables));
    },
    eliminaTrasferimento(variables: EliminaTrasferimentoMutationVariables): Promise<EliminaTrasferimentoMutation> {
      return withWrapper(() => client.request<EliminaTrasferimentoMutation>(print(EliminaTrasferimentoDocument), variables));
    },
    eliminaUtente(variables: EliminaUtenteMutationVariables): Promise<EliminaUtenteMutation> {
      return withWrapper(() => client.request<EliminaUtenteMutation>(print(EliminaUtenteDocument), variables));
    },
    firmaBusta(variables?: FirmaBustaMutationVariables): Promise<FirmaBustaMutation> {
      return withWrapper(() => client.request<FirmaBustaMutation>(print(FirmaBustaDocument), variables));
    },
    firmaConsegnaMassiva(variables?: FirmaConsegnaMassivaMutationVariables): Promise<FirmaConsegnaMassivaMutation> {
      return withWrapper(() => client.request<FirmaConsegnaMassivaMutation>(print(FirmaConsegnaMassivaDocument), variables));
    },
    firmaDifferita(variables: FirmaDifferitaMutationVariables): Promise<FirmaDifferitaMutation> {
      return withWrapper(() => client.request<FirmaDifferitaMutation>(print(FirmaDifferitaDocument), variables));
    },
    firmaTrasferimentoUfficio(variables?: FirmaTrasferimentoUfficioMutationVariables): Promise<FirmaTrasferimentoUfficioMutation> {
      return withWrapper(() => client.request<FirmaTrasferimentoUfficioMutation>(print(FirmaTrasferimentoUfficioDocument), variables));
    },
    inserisciArticolo(variables?: InserisciArticoloMutationVariables): Promise<InserisciArticoloMutation> {
      return withWrapper(() => client.request<InserisciArticoloMutation>(print(InserisciArticoloDocument), variables));
    },
    inserisciBarcode(variables: InserisciBarcodeMutationVariables): Promise<InserisciBarcodeMutation> {
      return withWrapper(() => client.request<InserisciBarcodeMutation>(print(InserisciBarcodeDocument), variables));
    },
    inserisciConsegna(variables: InserisciConsegnaMutationVariables): Promise<InserisciConsegnaMutation> {
      return withWrapper(() => client.request<InserisciConsegnaMutation>(print(InserisciConsegnaDocument), variables));
    },
    inserisciDestinatarioConsegna(variables: InserisciDestinatarioConsegnaMutationVariables): Promise<InserisciDestinatarioConsegnaMutation> {
      return withWrapper(() => client.request<InserisciDestinatarioConsegnaMutation>(print(InserisciDestinatarioConsegnaDocument), variables));
    },
    inserisciMagazzino(variables: InserisciMagazzinoMutationVariables): Promise<InserisciMagazzinoMutation> {
      return withWrapper(() => client.request<InserisciMagazzinoMutation>(print(InserisciMagazzinoDocument), variables));
    },
    inserisciMovimento(variables: InserisciMovimentoMutationVariables): Promise<InserisciMovimentoMutation> {
      return withWrapper(() => client.request<InserisciMovimentoMutation>(print(InserisciMovimentoDocument), variables));
    },
    inserisciTrasferimento(variables?: InserisciTrasferimentoMutationVariables): Promise<InserisciTrasferimentoMutation> {
      return withWrapper(() => client.request<InserisciTrasferimentoMutation>(print(InserisciTrasferimentoDocument), variables));
    },
    modificaBusta(variables: ModificaBustaMutationVariables): Promise<ModificaBustaMutation> {
      return withWrapper(() => client.request<ModificaBustaMutation>(print(ModificaBustaDocument), variables));
    },
    pingMutation(variables?: PingMutationMutationVariables): Promise<PingMutationMutation> {
      return withWrapper(() => client.request<PingMutationMutation>(print(PingMutationDocument), variables));
    },
    richiestaFirmaDifferita(variables: RichiestaFirmaDifferitaMutationVariables): Promise<RichiestaFirmaDifferitaMutation> {
      return withWrapper(() => client.request<RichiestaFirmaDifferitaMutation>(print(RichiestaFirmaDifferitaDocument), variables));
    },
    rimuoviDestinatarioConsegna(variables: RimuoviDestinatarioConsegnaMutationVariables): Promise<RimuoviDestinatarioConsegnaMutation> {
      return withWrapper(() => client.request<RimuoviDestinatarioConsegnaMutation>(print(RimuoviDestinatarioConsegnaDocument), variables));
    },
    rimuoviMagazzinoDaUtente(variables: RimuoviMagazzinoDaUtenteMutationVariables): Promise<RimuoviMagazzinoDaUtenteMutation> {
      return withWrapper(() => client.request<RimuoviMagazzinoDaUtenteMutation>(print(RimuoviMagazzinoDaUtenteDocument), variables));
    },
    OttieniBusta(variables: OttieniBustaQueryVariables): Promise<OttieniBustaQuery> {
      return withWrapper(() => client.request<OttieniBustaQuery>(print(OttieniBustaDocument), variables));
    },
    appleSignIn(variables: AppleSignInQueryVariables): Promise<AppleSignInQuery> {
      return withWrapper(() => client.request<AppleSignInQuery>(print(AppleSignInDocument), variables));
    },
    appleSignUp(variables: AppleSignUpQueryVariables): Promise<AppleSignUpQuery> {
      return withWrapper(() => client.request<AppleSignUpQuery>(print(AppleSignUpDocument), variables));
    },
    basicLogin(variables: BasicLoginQueryVariables): Promise<BasicLoginQuery> {
      return withWrapper(() => client.request<BasicLoginQuery>(print(BasicLoginDocument), variables));
    },
    getNewAccessToken(variables: GetNewAccessTokenQueryVariables): Promise<GetNewAccessTokenQuery> {
      return withWrapper(() => client.request<GetNewAccessTokenQuery>(print(GetNewAccessTokenDocument), variables));
    },
    login(variables: LoginQueryVariables): Promise<LoginQuery> {
      return withWrapper(() => client.request<LoginQuery>(print(LoginDocument), variables));
    },
    ottieniAnagrafichePoste(variables?: OttieniAnagrafichePosteQueryVariables): Promise<OttieniAnagrafichePosteQuery> {
      return withWrapper(() => client.request<OttieniAnagrafichePosteQuery>(print(OttieniAnagrafichePosteDocument), variables));
    },
    ottieniAnagrafichePosteFiltrate(variables?: OttieniAnagrafichePosteFiltrateQueryVariables): Promise<OttieniAnagrafichePosteFiltrateQuery> {
      return withWrapper(() => client.request<OttieniAnagrafichePosteFiltrateQuery>(print(OttieniAnagrafichePosteFiltrateDocument), variables));
    },
    ottieniArticoli(variables?: OttieniArticoliQueryVariables): Promise<OttieniArticoliQuery> {
      return withWrapper(() => client.request<OttieniArticoliQuery>(print(OttieniArticoliDocument), variables));
    },
    ottieniArticoliPerMagazzino(variables: OttieniArticoliPerMagazzinoQueryVariables): Promise<OttieniArticoliPerMagazzinoQuery> {
      return withWrapper(() => client.request<OttieniArticoliPerMagazzinoQuery>(print(OttieniArticoliPerMagazzinoDocument), variables));
    },
    ottieniArticolo(variables: OttieniArticoloQueryVariables): Promise<OttieniArticoloQuery> {
      return withWrapper(() => client.request<OttieniArticoloQuery>(print(OttieniArticoloDocument), variables));
    },
    ottieniArticoloPerBarcode(variables: OttieniArticoloPerBarcodeQueryVariables): Promise<OttieniArticoloPerBarcodeQuery> {
      return withWrapper(() => client.request<OttieniArticoloPerBarcodeQuery>(print(OttieniArticoloPerBarcodeDocument), variables));
    },
    ottieniBusteRai(variables: OttieniBusteRaiQueryVariables): Promise<OttieniBusteRaiQuery> {
      return withWrapper(() => client.request<OttieniBusteRaiQuery>(print(OttieniBusteRaiDocument), variables));
    },
    ottieniBusteRaiGiornaliere(variables?: OttieniBusteRaiGiornaliereQueryVariables): Promise<OttieniBusteRaiGiornaliereQuery> {
      return withWrapper(() => client.request<OttieniBusteRaiGiornaliereQuery>(print(OttieniBusteRaiGiornaliereDocument), variables));
    },
    ottieniConsegna(variables: OttieniConsegnaQueryVariables): Promise<OttieniConsegnaQuery> {
      return withWrapper(() => client.request<OttieniConsegnaQuery>(print(OttieniConsegnaDocument), variables));
    },
    ottieniConsegnaMassiva(variables?: OttieniConsegnaMassivaQueryVariables): Promise<OttieniConsegnaMassivaQuery> {
      return withWrapper(() => client.request<OttieniConsegnaMassivaQuery>(print(OttieniConsegnaMassivaDocument), variables));
    },
    ottieniConsegne(variables?: OttieniConsegneQueryVariables): Promise<OttieniConsegneQuery> {
      return withWrapper(() => client.request<OttieniConsegneQuery>(print(OttieniConsegneDocument), variables));
    },
    ottieniConsegneMassive(variables?: OttieniConsegneMassiveQueryVariables): Promise<OttieniConsegneMassiveQuery> {
      return withWrapper(() => client.request<OttieniConsegneMassiveQuery>(print(OttieniConsegneMassiveDocument), variables));
    },
    ottieniConsegnePerDestinatario(variables: OttieniConsegnePerDestinatarioQueryVariables): Promise<OttieniConsegnePerDestinatarioQuery> {
      return withWrapper(() => client.request<OttieniConsegnePerDestinatarioQuery>(print(OttieniConsegnePerDestinatarioDocument), variables));
    },
    ottieniCsvConsegne(variables?: OttieniCsvConsegneQueryVariables): Promise<OttieniCsvConsegneQuery> {
      return withWrapper(() => client.request<OttieniCsvConsegneQuery>(print(OttieniCsvConsegneDocument), variables));
    },
    ottieniCsvConsegneConIntervallo(variables: OttieniCsvConsegneConIntervalloQueryVariables): Promise<OttieniCsvConsegneConIntervalloQuery> {
      return withWrapper(() => client.request<OttieniCsvConsegneConIntervalloQuery>(print(OttieniCsvConsegneConIntervalloDocument), variables));
    },
    ottieniDestiantariConsegnaPerFullText(variables: OttieniDestiantariConsegnaPerFullTextQueryVariables): Promise<OttieniDestiantariConsegnaPerFullTextQuery> {
      return withWrapper(() => client.request<OttieniDestiantariConsegnaPerFullTextQuery>(print(OttieniDestiantariConsegnaPerFullTextDocument), variables));
    },
    ottieniDestinatariConsegna(variables?: OttieniDestinatariConsegnaQueryVariables): Promise<OttieniDestinatariConsegnaQuery> {
      return withWrapper(() => client.request<OttieniDestinatariConsegnaQuery>(print(OttieniDestinatariConsegnaDocument), variables));
    },
    ottieniDestinatariConsegnaPerMatricola(variables: OttieniDestinatariConsegnaPerMatricolaQueryVariables): Promise<OttieniDestinatariConsegnaPerMatricolaQuery> {
      return withWrapper(() => client.request<OttieniDestinatariConsegnaPerMatricolaQuery>(print(OttieniDestinatariConsegnaPerMatricolaDocument), variables));
    },
    ottieniDestinatariConsegnaPerNome(variables: OttieniDestinatariConsegnaPerNomeQueryVariables): Promise<OttieniDestinatariConsegnaPerNomeQuery> {
      return withWrapper(() => client.request<OttieniDestinatariConsegnaPerNomeQuery>(print(OttieniDestinatariConsegnaPerNomeDocument), variables));
    },
    ottieniFirmaBusta(variables: OttieniFirmaBustaQueryVariables): Promise<OttieniFirmaBustaQuery> {
      return withWrapper(() => client.request<OttieniFirmaBustaQuery>(print(OttieniFirmaBustaDocument), variables));
    },
    ottieniFirmaConsegna(variables: OttieniFirmaConsegnaQueryVariables): Promise<OttieniFirmaConsegnaQuery> {
      return withWrapper(() => client.request<OttieniFirmaConsegnaQuery>(print(OttieniFirmaConsegnaDocument), variables));
    },
    ottieniFotoBusta(variables: OttieniFotoBustaQueryVariables): Promise<OttieniFotoBustaQuery> {
      return withWrapper(() => client.request<OttieniFotoBustaQuery>(print(OttieniFotoBustaDocument), variables));
    },
    ottieniMagazzini(variables?: OttieniMagazziniQueryVariables): Promise<OttieniMagazziniQuery> {
      return withWrapper(() => client.request<OttieniMagazziniQuery>(print(OttieniMagazziniDocument), variables));
    },
    ottieniMagazzino(variables: OttieniMagazzinoQueryVariables): Promise<OttieniMagazzinoQuery> {
      return withWrapper(() => client.request<OttieniMagazzinoQuery>(print(OttieniMagazzinoDocument), variables));
    },
    ottieniMovimenti(variables?: OttieniMovimentiQueryVariables): Promise<OttieniMovimentiQuery> {
      return withWrapper(() => client.request<OttieniMovimentiQuery>(print(OttieniMovimentiDocument), variables));
    },
    ottieniMovimentiPerArticolo(variables: OttieniMovimentiPerArticoloQueryVariables): Promise<OttieniMovimentiPerArticoloQuery> {
      return withWrapper(() => client.request<OttieniMovimentiPerArticoloQuery>(print(OttieniMovimentiPerArticoloDocument), variables));
    },
    ottieniMovimentiPerMagazzino(variables: OttieniMovimentiPerMagazzinoQueryVariables): Promise<OttieniMovimentiPerMagazzinoQuery> {
      return withWrapper(() => client.request<OttieniMovimentiPerMagazzinoQuery>(print(OttieniMovimentiPerMagazzinoDocument), variables));
    },
    ottieniMovimento(variables: OttieniMovimentoQueryVariables): Promise<OttieniMovimentoQuery> {
      return withWrapper(() => client.request<OttieniMovimentoQuery>(print(OttieniMovimentoDocument), variables));
    },
    ottieniTotaleConsegneMagazzino(variables?: OttieniTotaleConsegneMagazzinoQueryVariables): Promise<OttieniTotaleConsegneMagazzinoQuery> {
      return withWrapper(() => client.request<OttieniTotaleConsegneMagazzinoQuery>(print(OttieniTotaleConsegneMagazzinoDocument), variables));
    },
    ottieniTotaleConsegneOperatori(variables?: OttieniTotaleConsegneOperatoriQueryVariables): Promise<OttieniTotaleConsegneOperatoriQuery> {
      return withWrapper(() => client.request<OttieniTotaleConsegneOperatoriQuery>(print(OttieniTotaleConsegneOperatoriDocument), variables));
    },
    ottieniTotaleMagazzini(variables?: OttieniTotaleMagazziniQueryVariables): Promise<OttieniTotaleMagazziniQuery> {
      return withWrapper(() => client.request<OttieniTotaleMagazziniQuery>(print(OttieniTotaleMagazziniDocument), variables));
    },
    ottieniTotaleMagazzino(variables: OttieniTotaleMagazzinoQueryVariables): Promise<OttieniTotaleMagazzinoQuery> {
      return withWrapper(() => client.request<OttieniTotaleMagazzinoQuery>(print(OttieniTotaleMagazzinoDocument), variables));
    },
    ottieniTotaleMagazzinoPerArticolo(variables: OttieniTotaleMagazzinoPerArticoloQueryVariables): Promise<OttieniTotaleMagazzinoPerArticoloQuery> {
      return withWrapper(() => client.request<OttieniTotaleMagazzinoPerArticoloQuery>(print(OttieniTotaleMagazzinoPerArticoloDocument), variables));
    },
    ottieniTrasferimenti(variables?: OttieniTrasferimentiQueryVariables): Promise<OttieniTrasferimentiQuery> {
      return withWrapper(() => client.request<OttieniTrasferimentiQuery>(print(OttieniTrasferimentiDocument), variables));
    },
    ottieniTrasferimento(variables: OttieniTrasferimentoQueryVariables): Promise<OttieniTrasferimentoQuery> {
      return withWrapper(() => client.request<OttieniTrasferimentoQuery>(print(OttieniTrasferimentoDocument), variables));
    },
    ottieniTrasferimentoUfficio(variables?: OttieniTrasferimentoUfficioQueryVariables): Promise<OttieniTrasferimentoUfficioQuery> {
      return withWrapper(() => client.request<OttieniTrasferimentoUfficioQuery>(print(OttieniTrasferimentoUfficioDocument), variables));
    },
    ping(variables?: PingQueryVariables): Promise<PingQuery> {
      return withWrapper(() => client.request<PingQuery>(print(PingDocument), variables));
    },
    quantitaArticoliConsegnatiPerMagazzino(variables: QuantitaArticoliConsegnatiPerMagazzinoQueryVariables): Promise<QuantitaArticoliConsegnatiPerMagazzinoQuery> {
      return withWrapper(() => client.request<QuantitaArticoliConsegnatiPerMagazzinoQuery>(print(QuantitaArticoliConsegnatiPerMagazzinoDocument), variables));
    },
    ristampaEtichetta(variables: RistampaEtichettaQueryVariables): Promise<RistampaEtichettaQuery> {
      return withWrapper(() => client.request<RistampaEtichettaQuery>(print(RistampaEtichettaDocument), variables));
    },
    scaricaReportBuste(variables: ScaricaReportBusteQueryVariables): Promise<ScaricaReportBusteQuery> {
      return withWrapper(() => client.request<ScaricaReportBusteQuery>(print(ScaricaReportBusteDocument), variables));
    },
    scaricaReportBusteCompatto(variables: ScaricaReportBusteCompattoQueryVariables): Promise<ScaricaReportBusteCompattoQuery> {
      return withWrapper(() => client.request<ScaricaReportBusteCompattoQuery>(print(ScaricaReportBusteCompattoDocument), variables));
    },
    utenteLoggato(variables?: UtenteLoggatoQueryVariables): Promise<UtenteLoggatoQuery> {
      return withWrapper(() => client.request<UtenteLoggatoQuery>(print(UtenteLoggatoDocument), variables));
    },
    utenti(variables?: UtentiQueryVariables): Promise<UtentiQuery> {
      return withWrapper(() => client.request<UtentiQuery>(print(UtentiDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;