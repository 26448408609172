// @ts-nocheck
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
} from "reactstrap";
import React, { FunctionComponent, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import store from "../../../store/store";
import { updated } from "../redux/utente.actions";
import GraphqlClient from "../../../client/graphql.client";

import { TextInputHook } from "../../../hooks/text_input.hook";
import Select from "react-select";

const UtenteUpdateComponent: FunctionComponent = (props: any) => {
  const [tags, setTags] = useState(props.data.tags);

  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);

  const magazzini = props.magazzini.ottieniMagazzini;

  const [utente_magazzino, setUtente_magazzino] = useState({
    ID: props.data.idMagazzino ? props.data.idMagazzino : null,
    descrizione: props.data.descrizioneMagazzino
      ? props.data.descrizioneMagazzino
      : null,
  });
/* //INUTILE
   const clearMagazzinoUtenteSelect = () => {
    const empty = { ID: null, descrizione: null };
    setUtente_magazzino(empty);
  }; 
 */

  //console.log("PORPS:", props)

  const salva = async () => {
    try {
      setLoading(true);

      const res = await GraphqlClient.aggiornaUtente({
        email: props.data.email,
        tags: tags === null ? [] : tags,
        idMagazzino: utente_magazzino.ID,
      });

      store.dispatch(updated(res.aggiornaUtente)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }

    props.toggle();
  };

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitError]);

  return (
    <Modal modalClassName="modal-black" isOpen={true} toggle={props.toggle}>
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h6 className="title title-up">Aggiorna</h6>
      </div>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            salva();
          }}
        >
          <FormGroup>
            <Label>Tags</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={tags}
              closeMenuOnSelect={false}
              isMulti
              getOptionLabel={(item) => item}
              getOptionValue={(item) => item}
              onChange={(value) => {
                setTags(value);
              }}
              closeMenuOnSelect={false}
              options={[
                "poste-saxa",
                "poste-mazzini",
                "poste-dear",
                "poste-teulada",
              ]}
              placeholder="tags"
            />
          </FormGroup>
          <FormGroup>
            <Label>Magazzino</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={utente_magazzino}
              //closeMenuOnSelect={false}
              //isMulti
              getOptionLabel={(item) => item.descrizione}
              getOptionValue={(item) => item.ID}
              onChange={(value) => {
                setUtente_magazzino(value);
              }}
              closeMenuOnSelect={true}
              options={magazzini}
              placeholder="magazzino"
            />
            {// INUTILE
            /* <Button
              className={"h-100 ml-3"}
              color="danger"
              size="sm"
              onClick={() => clearMagazzinoUtenteSelect()}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button> */}
          </FormGroup>
          {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
          <button
            style={{
              display: "none",
            }}
            type="submit"
          />
        </form>
        <CSSTransition
          in={submitError}
          appear={true}
          unmountOnExit
          classNames="error"
          timeout={300}
        >
          <blockquote className="blockquote blockquote-danger">
            <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
          </blockquote>
        </CSSTransition>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={salva}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UtenteUpdateComponent;
