import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
  Row,
  Col,
  Card,
} from "reactstrap";
import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import store from "../../../store/store";
import { created } from "../redux/destinatari.actions";
import GraphqlClient from "../../../client/graphql.client";

import { TextInputHook } from "../../../hooks/text_input.hook";
import { NumberInputHook } from "../../../hooks/number_input.hook";
import ClassNames from "classnames";
import Select from "react-select";

const DestinatariCreateComponent: FunctionComponent = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const destinatario_nome = TextInputHook("");
  const destinatario_cognome = TextInputHook("");
  const destinatario_matricola = TextInputHook("");
  const destinatario_ritiro = TextInputHook("");

  const salva = async () => {
    try {
      setLoading(true);

      const res = await GraphqlClient.inserisciDestinatarioConsegna({
        matricola: parseInt(destinatario_matricola.getValue()),
        nome: destinatario_nome.getValue(),
        cognome: destinatario_cognome.getValue(),
        ritiro: destinatario_ritiro.getValue(),
      });

      store.dispatch(created(res.inserisciDestinatarioConsegna));
      props.toggle();
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitError]);

  return (
    <Modal
      modalClassName={ClassNames("modal-black")}
      isOpen={props.show}
      toggle={props.toggle}
    >
      <Row>
        <Col xs={12} md={12}>
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={props.toggle}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Nuovo destinatario</h6>
          </div>
        </Col>

        {/*<Col xs={12} md={6} style={{ paddingLeft: 50, paddingTop: 40 }}>*/}
        <ModalBody>
          <FormGroup>
            <Label for="destinatario_matricola">Matricola</Label>
            <Input
              {...destinatario_matricola.input}
              name="destinatario_matricola"
              id="destinatario_matricola"
              placeholder="Inserisci destinatario_matricola"
            />
            <Label for="destinatario_nome">Nome</Label>
            <Input
              {...destinatario_nome.input}
              name="destinatario_nome"
              id="destinatario_nome"
              placeholder="Inserisci destinatario_nome"
            />
            <Label for="destinatario_cognome">Cognome</Label>
            <Input
              {...destinatario_cognome.input}
              name="destinatario_cognome"
              id="destinatario_cognome"
              placeholder="Inserisci destinatario_cognome"
            />
            <Label for="destinatario_ritiro">Punto di ritiro</Label>
            <Input
              {...destinatario_ritiro.input}
              name="destinatario_ritiro"
              id="destinatario_ritiro"
              placeholder="Inserisci destinatario_ritiro"
            />
          </FormGroup>
        </ModalBody>
        {/*</Col>*/}
      </Row>

      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={salva}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DestinatariCreateComponent;
