import React, {Component} from "react";
/*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR IMPORT*/
import Utente from "./pages/utente/utente.component";
import RaiBusta from "./pages/raiBusta/raiBusta.component";
import RaiAnagraficaPoste from "./pages/raiAnagraficaPoste/raiAnagraficaPoste.component";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/Login/login";
import FirmaDifferita from "./pages/firmaDifferita/firmaDifferita";
import Articoli from "./pages/articoli/articoli.component";
import Magazzini from "./pages/magazzini/magazzini.component";
import Consegne from "./pages/consegne/consegne.component";
import Trasferimenti from "./pages/trasferimenti/trasferimenti.component"
import Destinatari from "./pages/destinatari/destinatari.component"

const routes = [


    {
        path: "/login",
        icon: "tim-icons icon-image-02",
        name: "Login",
        component: Login,
        layout: "/auth",
        visible: false,
    },
    /*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR ROUTES*/
    {
        collapse: true,
        name: "Poste",
        state: "poste",
        icon: "icon-email-85",
        views: [
            {
                path: "/raiBusta",
                icon: "tim-icons icon-email-85",
                name: "Buste",
                component: RaiBusta,
                layout: "/admin",
            },
            {
                path: "/raiAnagraficaPoste",
                icon: "tim-icons icon-badge",
                name: "Anagrafiche",
                component: RaiAnagraficaPoste,
                layout: "/admin",
            },
        ]
    },

    {
        collapse: true,
        name: "Consegne",
        state: "consegne",
        icon: "tim-icons icon-bag-16",
        views: [
            {
                path: "/kpi",
                icon: "tim-icons icon-chart-bar-32",
                name: "KPI",
                component: Dashboard,
                layout: "/admin",
            },
            {
                path: "/articoli",
                icon: "tim-icons icon-bag-16",
                name: "Articoli",
                component: Articoli,
                layout: "/admin",
                roles: ["Amministratore"],

            },
            {
                path: "/magazzini",
                icon: "tim-icons icon-support-17",
                name: "Magazzini",
                component: Magazzini,
                layout: "/admin",
                roles: ["Amministratore"],

            },
            {
                path: "/consegne",
                icon: "tim-icons icon-send",
                name: "Consegne",
                component: Consegne,
                layout: "/admin",
            },
            {
                path: "/trasferimenti_carico_magazzino",
                icon: "tim-icons icon-world",
                name: "Trasferimenti/Carico Magazzino",
                component: Trasferimenti,
                layout: "/admin",
                roles: ["Amministratore"],

            },
            {
                path: "/destinatario_consegna",
                icon: "tim-icons icon-single-02",
                name: "Destinatario Consegna",
                component: Destinatari,
                layout: "/admin",
                roles: ["Amministratore"],

            }]
    },
    {
        path: "/utente",
        icon: "tim-icons icon-single-02",
        name: "Utente",
        component: Utente,
        layout: "/admin",
        roles: ["Amministratore"],
    },
];

export default routes;
