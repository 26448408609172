// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {updated} from "../redux/raiAnagraficaPoste.actions";
import GraphqlClient from "../../../client/graphql.client";


import {TextInputHook} from "../../../hooks/text_input.hook";
import {CheckBoxInputHook} from "../../../hooks/checkbox.hook";

const RaiAnagraficaPosteUpdateComponent: FunctionComponent = (props: { data: RaiAnagraficaPoste, toggle: Function }) => {


    const societa = TextInputHook(props.data.societa);
    const email = TextInputHook(props.data.email);
    const cap = TextInputHook(props.data.cap);
    const nome = TextInputHook(props.data.nome);
    const cognome = TextInputHook(props.data.cognome);
    const piano = TextInputHook(props.data.piano);
    const stanza = TextInputHook(props.data.stanza);
    const descrizione = TextInputHook(props.data.descrizione);
    const emailPreventiva = CheckBoxInputHook(props.data.emailPreventiva);
    const id = TextInputHook(props.data.id);
    const indirizzo = TextInputHook(props.data.indirizzo);
    const citta = TextInputHook(props.data.citta);

    const [submitError, setSubmitError] = useState(false);
    const [loading, setLoading] = useState(false);

    const salva = async () => {
        try {
            setLoading(true);

            const res = await GraphqlClient.aggiornaAnagraficaPoste(
                {
                    societa: societa.getValue(),
                    email: email.getValue(),
                    cap: cap.getValue(),
                    nome: nome.getValue(),
                    cognome: cognome.getValue(),
                    piano: piano.getValue(),
                    stanza: stanza.getValue(),
                    descrizione: descrizione.getValue(),
                    emailPreventiva: emailPreventiva.getValue(),
                    id: props.data.id,
                    indirizzo: indirizzo.getValue(),
                    citta: citta.getValue()
                }
            );

            store.dispatch(updated(res.aggiornaAnagraficaPoste)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.error(e);
            setSubmitError(true);
        } finally {
            setLoading(false);
        }

        props.toggle();
    };

    useEffect(() => {
        if (submitError) {
            const timer = setTimeout(() => {
                setSubmitError(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [submitError]);

    return (
        <Modal
            modalClassName="modal-black"
            isOpen={true}
            toggle={props.toggle}
        >
            <div className="modal-header justify-content-center">
                <button
                    aria-hidden={true}
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    <i className="tim-icons icon-simple-remove"/>
                </button>
                <h6 className="title title-up">Aggiorna</h6>
            </div>
            <ModalBody>
                <form onSubmit={e => {
                    e.preventDefault();
                    salva();
                }}>

                    <FormGroup>
                        <Label for="descrizione">Descrizione</Label>
                        <Input
                            {...descrizione.input}
                            name="descrizione"
                            id="descrizione"
                            placeholder="Inserisci descrizione"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="nome">Nome</Label>
                        <Input
                            {...nome.input}
                            name="nome"
                            id="nome"
                            placeholder="Inserisci nome"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="cognome">Cognome</Label>
                        <Input
                            {...cognome.input}
                            name="cognome"
                            id="cognome"
                            placeholder="Inserisci cognome"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input
                            {...email.input}
                            name="email"
                            id="email"
                            placeholder="Inserisci email"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="cap">Cap</Label>
                        <Input
                            {...cap.input}
                            name="cap"
                            id="cap"
                            placeholder="Inserisci cap"
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="piano">Piano</Label>
                        <Input
                            {...piano.input}
                            name="piano"
                            id="piano"
                            placeholder="Inserisci piano"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="stanza">Stanza</Label>
                        <Input
                            {...stanza.input}
                            name="stanza"
                            id="stanza"
                            placeholder="Inserisci stanza"
                        />
                    </FormGroup>

                    <FormGroup check>
                        <Label check>
                            <Input
                                {...emailPreventiva.input} type="checkbox"/>
                           Email preventiva
                            <span className="form-check-sign">
                        <span className="check"></span>
                     </span>
                        </Label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="id">id</Label>
                        <Input
                            {...id.input}
                            name="id"
                            id="id"
                            placeholder="Inserisci id"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="indirizzo">Indirizzo</Label>
                        <Input
                            {...indirizzo.input}
                            name="indirizzo"
                            id="indirizzo"
                            placeholder="Inserisci indirizzo"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="citta">Citta</Label>
                        <Input
                            {...citta.input}
                            name="citta"
                            id="citta"
                            placeholder="Inserisci citta"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="societa">Societa</Label>
                        <Input
                            {...societa.input}
                            name="societa"
                            id="societa"
                            placeholder="Inserisci societa"
                        />
                    </FormGroup>
                    {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
                    <button style={{
                        display:
                            'none'
                    }} type="submit"/>
                </form>
                <CSSTransition
                    in={submitError}
                    appear={true}
                    unmountOnExit
                    classNames="error"
                    timeout={300}
                >
                    <blockquote className="blockquote blockquote-danger">
                        <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
                    </blockquote>
                </CSSTransition>

            </ModalBody>
            <ModalFooter>
                <Button
                    color="danger"
                    data-dismiss="modal"
                    type="button"
                    onClick={props.toggle}
                >
                    Annulla
                </Button>
                <Button
                    color="primary"
                    type="button"
                    onClick={salva}
                >
                    {loading ?
                        <i className="fa fa-spin fa-spinner"/>
                        : 'Salva'}
                </Button>
            </ModalFooter>
        </Modal>
    );

}

export default RaiAnagraficaPosteUpdateComponent;
