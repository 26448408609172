import React, {Component} from "react";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button,
    UncontrolledTooltip,
    CardSubtitle,
} from "reactstrap";

import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from "react-redux";
import {get} from "lodash";
import "./redux/consegne.reducer";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from "lodash";

import {
    readedAll,
    requestDelete,
    deleted,
    deleteError,
} from "./redux/consegne.actions";

import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import moment from "moment";
import ShowConfirmationDialog from "../../components/ShowConfirmationDialog";
import Viewer from "react-viewer";

class ConsegneComponent extends Component {

    state = {
        data: [],
        loading: false,
        showCreateModal: false,
        showUpdateModal: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {},
        loadingRow: '',
        loadingCsv: false,
    };

    caricaConsegne = async () => {
        try {
            const response = await GraphqlClient.ottieniConsegne();

            store.dispatch(readedAll(response.ottieniConsegne)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log("Impossibile caricare i dati", e);
        }
    };

    componentDidMount() {
        this.caricaConsegne();
    }

    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal,
        });
    };

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record,
        });
    };

    elimina = async () => {
        try {
            this.toggleDeleteAlert(this.state.selectedRecordForDelete);
            store.dispatch(requestDelete(this.state.selectedRecordForDelete.ID))
            await GraphqlClient.eliminaConsegna({id: this.state.selectedRecordForDelete.ID});
            store.dispatch(deleted({id: this.state.selectedRecordForDelete.ID})); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile eliminare il record', e);
            store.dispatch(deleteError(this.state.selectedRecordForDelete.ID));
        }
        this.caricaConsegne();
    }

    toggleDeleteAlert = (record) =>
        this.setState({
            showDeleteAlert: !this.state.showDeleteAlert,
            selectedRecordForDelete: record,
        });

    actions = (cellInfo) => (
        <div className="actions-right">
            {get(cellInfo, "original._deleting", false) ? (
                <i className="fa fa-spin fa-spinner"/>
            ) : null}
            <Button
                color="warning"
                size="sm"
                onClick={() => this.toggleShowUpdateModal(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-pencil"/>
            </Button>{" "}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );

    downloadCsv = async () => {

        this.setState({loadingCsv: true})
        try {
            const res = await GraphqlClient.ottieniCsvConsegne()

            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.ottieniCsvConsegne}`;
            const downloadLink = document.createElement("a");
            const fileName = "consegne.xlsx";

            downloadLink.target = '_blank';
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink); // Required for this to work in FireFox

            downloadLink.click();
        } catch (e) {
            alert("Impossibile scaricare xls");
        } finally {
            this.setState({loadingCsv: false})
        }

    }

    render() {
        return (
            <>
                {this.state.showDeleteAlert ? (
                    <ReactBSAlert
                        warning
                        style={{display: "block"}}
                        title="Confermare l'eliminazione ?"
                        onConfirm={() => this.elimina()}
                        onCancel={() => this.toggleDeleteAlert()}
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText="Si"
                        cancelBtnText="No"
                        showCancel
                        btnSize=""
                    >
                        Stai per eliminare in modo definitivo:{" "}
                        {_.get(this.state, "selectedRecordForDelete.name", "")}
                    </ReactBSAlert>
                ) : null}

                <Viewer
                    visible={this.state.showSign}
                    onClose={() => this.setState({showSign: false})}
                    images={[{src: this.state.sign, alt: this.state.signAlt}]}
                />

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            <button
                                                className="btn btn-primary btn-fab "

                                                onClick={this.downloadCsv}
                                            >
                                                EXCEL
                                            </button>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <button
                                                id="refresh"
                                                onClick={() => this.caricaConsegne()}
                                                className="btn btn-primary btn-fab btn-icon "
                                            >
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>

                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target="refresh"
                                                delay={0}
                                            >
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista Consegne</CardTitle>
                                    <Fade when={this.props.loading}>
                                        <CardSubtitle>
                                            Caricamento in corso{" "}
                                            <i className="fa fa-spin fa-spinner"/>
                                        </CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={false}
                                        columns={[
                                            {Header: "destinatario", accessor: "destinatario"},

                                            {Header: "barcode", accessor: "barcode"},
                                            {Header: "magazzino", accessor: "descrizioneMagazzino"},
                                            {
                                                Header: "Firma",
                                                Cell: row => {
                                                    if (this.state.loadingRow === row.original.ID) {
                                                        return <i className="fa fa-spin fa-spinner"/>
                                                    }

                                                    return (
                                                        <Button
                                                            className="btn-link btn-icon btn-primary"
                                                            data-toggle="dropdown"
                                                            color={"white"}
                                                            type="button"
                                                            onClick={async () => {
                                                                this.setState({loadingRow: row.original.ID});
                                                                const res = await GraphqlClient.ottieniFirmaConsegna({id: row.original.ID});
                                                                this.setState({loadingRow: ""});
                                                                if (res.hasError) {
                                                                    ShowConfirmationDialog({
                                                                        title: "Errore durante l'ottenimento della firma",
                                                                        text: res.message,
                                                                        confirmBtnText: 'Ok',
                                                                        canEscapeKeyCancel: true,
                                                                        showCancel: false
                                                                    });
                                                                    return;
                                                                }
                                                                this.setState({
                                                                    showSign: true,
                                                                    sign: "data:image/jpeg;base64," + res.ottieniFirmaConsegna,
                                                                    signAlt: "Firma"
                                                                });
                                                            }}
                                                        >
                                                            <i className="tim-icons icon-image-02"/>
                                                        </Button>
                                                    )
                                                        ;
                                                },
                                                filterable: false,
                                            },
                                            {
                                                Header: "data",
                                                accessor: "data",
                                                Cell: (row) => {
                                                    let date = moment.unix(new Date(row.value)).format(
                                                        "DD/MM/YYYY HH:mm"
                                                    );
                                                    return date;
                                                },
                                            },
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={"Avanti"}
                                        previousText={"Indietro"}
                                        pageText={"Pagina"}
                                        ofText={"di"}
                                        rowsText={"elementi"}
                                        noDataText={"Nessun dato"}
                                        loadingText={"Caricamento in corso"}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "consegne.data", []),
        loading: get(state, "consegne.loading", false),
    };

    if (data.data === null) data.data = [];

    return data;
};
export default connect(mapStateToProps, () => ({}))(ConsegneComponent);
