// @ts-nocheck
import {Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label, FormText} from "reactstrap";
import React, {FunctionComponent, useState, useEffect} from "react";
import {CSSTransition} from "react-transition-group";
import store from "../../../store/store";
import {created} from "../redux/raiBusta.actions";
import GraphqlClient from "../../../client/graphql.client";

import {TextInputHook} from "../../../hooks/text_input.hook";
import {CheckBoxInputHook} from "../../../hooks/checkbox.hook";
const RaiBustaCreateComponent:FunctionComponent = (props: any) => {

  
  const mittente = TextInputHook("digital");  
  const destinatario = TextInputHook("intangible");  
  const barcode = TextInputHook("Fantastic");  
  const foto = TextInputHook("portals");  
  const emailPreventiva = CheckBoxInputHook(true);  
  const ufficioRicezione = TextInputHook("Soft");  

  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);

  const salva = async () => {
    try {
      setLoading(true);

      const res = await GraphqlClient.creaBustaRai(
        {
	mittente: mittente.getValue(),
	destinatario: destinatario.getValue(),
	barcode: barcode.getValue(),
	foto: foto.getValue(),
	emailPreventiva: emailPreventiva.getValue(),
	ufficioRicezione: ufficioRicezione.getValue()
}
    );

      store.dispatch(created(res.creaBustaRai)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }

    props.toggle();
  };

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }

  }, [submitError]);

  return (
    <Modal
      modalClassName="modal-black"
      isOpen={props.show}
      toggle={props.toggle}
    >
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          <i className="tim-icons icon-simple-remove"/>
        </button>
        <h6 className="title title-up">Nuovo</h6>
      </div>
      <ModalBody>
        <form onSubmit={e => {
          e.preventDefault();
          salvaLotto();
        }}>

          
          <FormGroup>
                    <Label for="mittente">mittente</Label>
                    <Input
                          {...mittente.input}
                          name="mittente"
                          id="mittente"
                          placeholder="Inserisci mittente"
                    />
                 </FormGroup>          
          <FormGroup>
                    <Label for="destinatario">destinatario</Label>
                    <Input
                          {...destinatario.input}
                          name="destinatario"
                          id="destinatario"
                          placeholder="Inserisci destinatario"
                    />
                 </FormGroup>          
          <FormGroup>
                    <Label for="barcode">barcode</Label>
                    <Input
                          {...barcode.input}
                          name="barcode"
                          id="barcode"
                          placeholder="Inserisci barcode"
                    />
                 </FormGroup>          
          <FormGroup>
                    <Label for="foto">foto</Label>
                    <Input
                          {...foto.input}
                          name="foto"
                          id="foto"
                          placeholder="Inserisci foto"
                    />
                 </FormGroup>          
          <FormGroup check>
                 <Label check>
                        <Input
                        {...emailPreventiva.input} type="checkbox"/>
               emailPreventiva
                     <span className="form-check-sign">
                        <span className="check"></span>
                     </span>
                 </Label>
              </FormGroup>          
          <FormGroup>
                    <Label for="ufficioRicezione">ufficioRicezione</Label>
                    <Input
                          {...ufficioRicezione.input}
                          name="ufficioRicezione"
                          id="ufficioRicezione"
                          placeholder="Inserisci ufficioRicezione"
                    />
                 </FormGroup>          

          {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
          <button style={{display: 'none'}} type="submit"/>
        </form>
        <CSSTransition
          in={submitError}
          appear={true}
          unmountOnExit
          classNames="error"
          timeout={300}
        >
          <blockquote className="blockquote blockquote-danger">
            <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
          </blockquote>
        </CSSTransition>

      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button
          color="primary"
          type="button"
          onClick={salva}
        >
          {loading ?
            <i className="fa fa-spin fa-spinner"/>
            :'Salva'}
        </Button>
      </ModalFooter>
    </Modal>
  );

}

export default RaiBustaCreateComponent;
