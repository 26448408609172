import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import {Bar, Line} from "react-chartjs-2";
// react plugin for creating vector maps

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col, CardSubtitle
} from "reactstrap";

// core components
import {
    chartExample1
} from "../../variables/charts.jsx";
import {chartExample7} from "../../variables/charts";
import SortingTable from "../../components/SortingTable/SortingTable";
import Fade from "react-reveal/Fade";
import GraphqlClient from "../../client/graphql.client";


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingGiacenzeMagazzino: false,
            loadingArticoliConsegnati: false,
            loadingArticoliConsegnatiOperatore: false,
            giacenzeMagazzino: [],
            articoliConsegnati: [],
            articoliConsegnatiPerOperatore: [],
        };
    }

    componentDidMount() {
        this.caricaGiancezeMagazzino();
        this.caricaArticoliConsegnatiPerOperatore();
        this.caricaArticoliConsegnati();
    }

    caricaGiancezeMagazzino = async () => {
        try {
            this.setState({loadingGiacenzeMagazzino: true})
            const res = await GraphqlClient.ottieniTotaleMagazzini({})
            this.setState({giacenzeMagazzino: res.ottieniTotaleMagazzini})
        } catch (e) {
            alert("Impossibile caricare giacenze magazzino")
        } finally {
            this.setState({loadingGiacenzeMagazzino: false})
        }
    }


    caricaArticoliConsegnati = async () => {
        try {
            this.setState({loadingArticoliConsegnati: true})
            const res = await GraphqlClient.ottieniTotaleConsegneMagazzino({})
            this.setState({articoliConsegnati: res.ottieniTotaleConsegneMagazzino})
        } catch (e) {

        } finally {
            this.setState({loadingArticoliConsegnati: false})
        }
    }

    caricaArticoliConsegnatiPerOperatore = async () => {
        try {
            this.setState({loadingArticoliConsegnatiOperatore: true})
            const res = await GraphqlClient.ottieniTotaleConsegneOperatori({})
            this.setState({articoliConsegnatiPerOperatore: res.ottieniTotaleConsegneOperatori})
        } catch (e) {

        } finally {
            this.setState({loadingArticoliConsegnatiOperatore: false})
        }
    }


    setBgChartData = name => {
        this.setState({
            bigChartData: name
        });
    };

    render() {

        const dataMagazzini = this.state.articoliConsegnati.map(item => (
            {
                data: [
                    {text: item.articolo},
                    {text: item.magazzino},
                    {text: item.totale},
                ]
            }
        ));

        const dataArticoliConsegnatiOperatore = this.state.articoliConsegnatiPerOperatore.map(item => (
            {
                data: [
                    {text: item.operatore},
                    {text: item.articolo},
                    {text: item.totale},
                ]
            }
        ));

        const giacenzeMagazzini = this.state.giacenzeMagazzino.map(item => (
            {
                data: [
                    {text: item.descrizioneMagazzino},
                    {text: item.descrizioneArticolo},
                    {text: item.quantita},
                ]
            }
        ));

        console.log('@@@@@CONSE', this.state.dataArticoliConsegnatiOperatore)

        return (
            <>
                <div className="content">
                    <Row>
                        <Col className="mb-5" md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Articoli consegnati ( per magazzino ) </CardTitle>
                                    <Fade when={this.state.loadingArticoliConsegnati}>
                                        <CardSubtitle>
                                            Caricamento in corso{" "}
                                            <i className="fa fa-spin fa-spinner"/>
                                        </CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    {dataMagazzini.length > 0 && <SortingTable
                                        thead={[
                                            {text: "Articolo"},
                                            {text: "Magazzino"},
                                            {text: "Qta"},
                                        ]}
                                        tbody={
                                            dataMagazzini
                                        }
                                    />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-5" md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Articoli consegnati ( per operatore ) </CardTitle>
                                    <Fade when={this.state.loadingArticoliConsegnatiOperatore}>
                                        <CardSubtitle>
                                            Caricamento in corso{" "}
                                            <i className="fa fa-spin fa-spinner"/>
                                        </CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    {dataArticoliConsegnatiOperatore.length > 0 && <SortingTable
                                        thead={[
                                            {text: "Operatore"},
                                            {text: "Articolo"},
                                            {text: "Qta"},
                                        ]}
                                        tbody={
                                            dataArticoliConsegnatiOperatore
                                        }
                                    />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-5" md="12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Giacenze magazzino</CardTitle>
                                    <Fade when={this.state.loadingGiacenzeMagazzino}>
                                        <CardSubtitle>
                                            Caricamento in corso{" "}
                                            <i className="fa fa-spin fa-spinner"/>
                                        </CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    {giacenzeMagazzini.length > 0 && <SortingTable
                                        thead={[
                                            {text: "Operatore"},
                                            {text: "Articolo"},
                                            {text: "Qta"},
                                        ]}
                                        tbody={
                                           giacenzeMagazzini
                                        }
                                    />}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default Dashboard;
