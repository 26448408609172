import React, {Component} from 'react';
import {
    ButtonDropdown,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Button, UncontrolledTooltip, CardSubtitle,
    DropdownMenu,
    DropdownItem,
    DropdownToggle
} from "reactstrap";

import ReactTable from "react-table";
import classNames from "classnames";
import {connect} from 'react-redux';
import {get} from 'lodash';
import './redux/raiBusta.reducer';
import RaiBustaCreateComponent from "./components/raiBusta.create.component";
import RaiBustaUpdateComponent from "./components/raiBusta.update.component";
import Fade from "react-reveal/Fade";
import ReactBSAlert from "react-bootstrap-sweetalert";
import _ from 'lodash';
import {readedAll, requestDelete, deleted, deleteError} from "./redux/raiBusta.actions";
import GraphqlClient from "../../client/graphql.client";
import store from "../../store/store";
import moment from 'moment';
import Viewer from 'react-viewer';
import DateRangePickerWrapper from '../../components/DateRangePicker/DateRangePickerWrapper';
import ReactTableFilterSelectComponent from "../../components/ReactTableFilter/reactTable.filter.select.component";

class raiBustaComponent extends Component {

    state = {
        data: [],
        loading: false,
        showCreateModal: false,
        showUpdateModal: false,
        selectedRecordForUpdate: {},
        showDeleteAlert: false,
        selectedRecordForDelete: {},
        showImage: false,
        imageAlt: '',
        showExportDropDown: false,
        image: '',
        downloadReportInProgress: false,
        startDate: new moment(),
        endDate: new moment(),
        initialStartDate: new moment(),
        initialEndDate: new moment(),
        filters: {}
    }

    caricaDati = async (startDate, endDate) => {
        const startDateClone = startDate.clone();
        const endDateClone = endDate.clone();

        startDateClone.set({hour: 0, minute: 0, second: 0, millisecond: 0});
        endDateClone.set({hour: 23, minute: 59, second: 59, millisecond: 0});

        try {
            const response = await GraphqlClient.ottieniBusteRai(
                {startDate: startDateClone.unix(), endDate: endDateClone.unix()}
            );
            store.dispatch(readedAll(response.ottieniBusteRai)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile caricare i dati', e);
        }
    }

    onDatesChanged(startDate, endDate) {

        this.setState(
            {
                startDate,
                endDate
            }, () => {
                this.caricaDati(startDate, endDate)
            });
    }


    componentDidMount() {
        this.caricaDati(this.state.initialStartDate, this.state.initialEndDate);
    }


    toggleShowCreateModal = () => {
        this.setState({
            showCreateModal: !this.state.showCreateModal
        })
    }

    toggleShowUpdateModal = (record) => {
        this.setState({
            showUpdateModal: !this.state.showUpdateModal,
            selectedRecordForUpdate: record
        })
    }

    dataTableIds = () => {
        const wrappedInstance = this.checkboxTable;

        // the 'sortedData' property contains the currently accessible records based on the filter and sort
        const currentRecords = wrappedInstance ? wrappedInstance.getResolvedState().sortedData : this.props.data;
        const data = currentRecords.map(item => item._original.id);
        return data;
    };

    downloadReportCompleto = async () => {
        try {
            this.setState({downloadReportInProgress: true});
            const ids = this.dataTableIds();

            const res = await GraphqlClient.scaricaReportBuste({idBuste: ids});

            if (res.error) {
                alert("Impossibile scaricare il report")
                return;
            }

            window.open(res.scaricaReportBuste, '_blank');

        } catch (e) {
            alert("Creazione report non riuscita");
        } finally {
            this.setState({downloadReportInProgress: false});

        }


    };
    downloadReportCompatto = async () => {
        try {
            this.setState({downloadReportInProgress: true});
            const ids = this.dataTableIds();
            const res = await GraphqlClient.scaricaReportBusteCompatto({idBuste: ids});

            if (res.error) {
                alert("Impossibile scaricare il report")
                return;
            }

            window.open(res.scaricaReportBusteCompatto, '_blank');

        } catch (e) {
            alert("Creazione report non riuscita");
        } finally {
            this.setState({downloadReportInProgress: false});

        }
    };

    elimina = async () => {
        try {
            this.toggleDeleteAlert(this.state.selectedRecordForDelete);
            store.dispatch(requestDelete(this.state.selectedRecordForDelete.id))
            await GraphqlClient.eliminaBusta({id: this.state.selectedRecordForDelete.id});
            store.dispatch(deleted({id: this.state.selectedRecordForDelete.id})); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
        } catch (e) {
            console.log('Impossibile eliminare il record', e);
            store.dispatch(deleteError(this.state.selectedRecordForDelete.id));
        }

    }

    toggleDeleteAlert = (record) => this.setState({
        showDeleteAlert: !this.state.showDeleteAlert,
        selectedRecordForDelete: record
    });

    actions = (cellInfo) => (
        <div className="actions-right">
            {
                get(cellInfo, "original._deleting", false) ? <i className="fa fa-spin fa-spinner"/> : null
            }

           <Button
                color="warning"
                size="sm"
                onClick={async () => {
                   try {
                    await GraphqlClient.ristampaEtichetta({id: cellInfo.original.id});
                    alert("Ristampa eseguita")
                } catch (e) {
                    console.log('E', e);
                    alert("Impossibile effettuare ristampa etichetta")
                }
                }}
                className={classNames("btn-icon btn-link like")}>
                <i className="tim-icons icon-paper"/>
            </Button>{" "}
            <Button
                color="danger"
                size="sm"
                onClick={() => this.toggleDeleteAlert(cellInfo.original)}
                className={classNames("btn-icon btn-link like")}
            >
                <i className="tim-icons icon-simple-remove"/>
            </Button>{" "}
        </div>
    );


    toggleShowExportDropDown = () => {
        this.setState({showExportDropDown: !this.state.showExportDropDown});
    }

    render() {
        return (
            <>

                {
                    this.state.showDeleteAlert ?
                        <ReactBSAlert
                            warning
                            style={{display: "block"}}
                            title="Confermare l'eliminazione ?"
                            onConfirm={() => this.elimina()}
                            onCancel={() => this.toggleDeleteAlert()}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            showCancel
                            btnSize=""
                        >
                            Stai per eliminare in modo
                            definitivo: {_.get(this.state, "selectedRecordForDelete.name", "")}
                        </ReactBSAlert> : null
                }
                <RaiBustaCreateComponent show={this.state.showCreateModal} toggle={this.toggleShowCreateModal}/>

                {this.state.showUpdateModal ? <RaiBustaUpdateComponent data={this.state.selectedRecordForUpdate}
                                                                       toggle={this.toggleShowUpdateModal}/> : null
                }

                <Viewer
                    visible={this.state.showImage}
                    onClose={() => this.setState({showImage: false})}
                    images={[{src: this.state.image, alt: this.state.imageAlt}]}
                />

                {/*Contenuto pagina*/}
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardBody>
                                    {/*Filtri*/}
                                    <Row>
                                        <Col className="mr-auto">
                                            <DateRangePickerWrapper
                                                disabled={false}
                                                initialStartDate={this.state.initialStartDate} // momentPropTypes.momentObj or null,
                                                startDateId="startDate" // PropTypes.string.isRequired,
                                                initialEndDate={this.state.initialEndDate} // momentPropTypes.momentObj or null,
                                                endDateId="endDate" // PropTypes.string.isRequired,
                                                small
                                                enableOutsideDays={true}
                                                startDatePlaceholderText={"Data inizio"}
                                                endDatePlaceholderText={"Data fine"}
                                                showDefaultInputIcon
                                                isOutsideRange={() => false}
                                                onChange={(startDate, endDate) => this.onDatesChanged(startDate, endDate)}
                                            />
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <ButtonDropdown isOpen={this.state.showExportDropDown}
                                                            toggle={this.toggleShowExportDropDown}>
                                                <DropdownToggle className="btn btn-primary" caret>
                                                    Scarica
                                                    report {this.state.downloadReportInProgress ? "(Download in corso...)" : null}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem
                                                        onClick={() => this.downloadReportCompleto()}>Report
                                                        completo</DropdownItem>
                                                    <DropdownItem
                                                        onClick={() => this.downloadReportCompatto()}>Report
                                                        compatto</DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                            <button id="refresh" onClick={() => {
                                                this.caricaDati(this.state.startDate, this.state.endDate);
                                            }} className="btn btn-primary btn-fab btn-icon ">
                                                <i className="tim-icons icon-refresh-01"></i>
                                            </button>
                                            <UncontrolledTooltip placement="bottom" target="refresh" delay={0}>
                                                Ricarica i dati
                                            </UncontrolledTooltip>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Lista</CardTitle>
                                    <Fade when={this.props.loading}>
                                        <CardSubtitle>Caricamento in corso <i
                                            className="fa fa-spin fa-spinner"/></CardSubtitle>
                                    </Fade>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        data={this.props.data}
                                        filterable
                                        resizable={true}
                                        ref={r => (this.checkboxTable = r)}

                                        columns={[
                                            {
                                                "Header": "foto",
                                                Cell: row => {
                                                    return (
                                                        <Button
                                                            className="btn-link btn-icon btn-primary"
                                                            data-toggle="dropdown"
                                                            color={"white"}
                                                            type="button"
                                                            onClick={async () => {
                                                                try {
                                                                    const res = await GraphqlClient.ottieniFotoBusta({id: row.original.id});
                                                                    this.setState({
                                                                        showImage: true,
                                                                        image: "data:image/jpeg;base64," + res.ottieniFotoBusta,
                                                                        imageAlt: "Foto busta"
                                                                    });
                                                                } catch (e) {
                                                                    alert("Impossibile ottenere foto")
                                                                }
                                                            }}
                                                        >
                                                            <i className="tim-icons icon-image-02"/>
                                                        </Button>
                                                    )
                                                        ;
                                                },
                                                filterable: false,
                                            },

                                            {
                                                "Header": "Codice raccomandata", "accessor": "barcode",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    if (!filter.value) return true;
                                                    return filter.value.some(f => f.value === row.barcode);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'barcode';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['barcode'])}
                                                        optionValue={'barcode'}
                                                        optionLabel={'barcode'}
                                                        filterName
                                                        value={this.state.filters[filterName]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                }
                                            },
                                            {
                                                "Header": "Mittente", "accessor": "mittente.cognomenome",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    if (!filter.value) return true;

                                                    return filter.value.some(f => f.value === row._original.mittente?.cognomenome);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'mittente';
                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['mittente.cognomenome'])}
                                                        optionValue={'mittente.cognomenome'}
                                                        optionLabel={'mittente.cognomenome'}
                                                        filterName
                                                        value={this.state.filters["mittente"]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                },
                                            },
                                            {
                                                "Header": "Destinatario", "accessor": "destinatario.cognomenome",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    if (!filter.value) return true;
                                                    return filter.value.some(f => f.value === row._original.destinatario.cognomenome);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'destinatario';
                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['destinatario.cognomenome'])}
                                                        optionValue={'destinatario.cognomenome'}
                                                        optionLabel={'destinatario.cognomenome'}
                                                        filterName
                                                        value={this.state.filters["destinatario"]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                },
                                            },

                                            {
                                                "Header": "Data ricezione", "accessor": "oraRicezione",
                                                Cell: row => {
                                                    return (`${moment.unix(row.value).format("DD-MM-YYYY HH:mm")}`)
                                                },
                                                filterable: false
                                            },
                                            {
                                                "Header": "Uff. ricezione", "accessor": "ufficioRicezione",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    if (!filter.value) return true;
                                                    return filter.value.some(f => f.value === row.ufficioRicezione);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'ufficioRicezione';
                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['ufficioRicezione'])}
                                                        optionValue={'ufficioRicezione'}
                                                        optionLabel={'ufficioRicezione'}
                                                        filterName
                                                        value={this.state.filters["ufficioRicezione"]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                },
                                            },
                                            {
                                                "Header": "Operatore ricevente",
                                                "accessor": "operatoreLoggatoScansione",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    if (!filter.value) return true;
                                                    return filter.value.some(f => f.value === row.operatoreLoggatoScansione);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'operatoreLoggatoScansione';

                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['operatoreLoggatoScansione'])}
                                                        optionValue={'operatoreLoggatoScansione'}
                                                        optionLabel={'operatoreLoggatoScansione'}
                                                        filterName
                                                        value={this.state.filters["operatoreLoggatoScansione"]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                },
                                            },
                                            {
                                                "Header": "stato",
                                                "accessor": "stato",
                                                Cell: (cellInfo => {
                                                    const state = cellInfo.original.stato.toLowerCase();
                                                    switch (state) {
                                                        case "ricevuto":
                                                            return <div className={"bustaCreato"}>{state}</div>
                                                        case "firmato":
                                                            return <div className={"bustaFirmato"}>{state}</div>
                                                        case "eliminato":
                                                            return <div className={"bustaEliminato"}>{state}</div>
                                                        case "altra competenza":
                                                            return <div className={"bustaAltraCompetenza"}>{state}</div>
                                                        default:
                                                            return <div className={""}>{state}</div>
                                                    }
                                                }),
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    if (!filter.value) return true;
                                                    return filter.value.some(f => f.value === row.stato);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'stato';
                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['stato'])}
                                                        optionValue={'stato'}
                                                        optionLabel={'stato'}
                                                        filterName
                                                        value={this.state.filters["stato"]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                },
                                            },
                                            {
                                                "Header": "Tentativi consegna",
                                                "accessor": "tentativiDiConsegnaEffetuati",
                                                filterable: false,
                                                Cell: row => {
                                                    let orari = [];
                                                    row.original.orariTentativiDiConsegna && row.original.orariTentativiDiConsegna.forEach(orario => {
                                                            orari.push(moment.unix(orario).format("DD/MM/YY HH:mm"));
                                                        }
                                                    );
                                                    return (
                                                        <>
                                                            <div style={{textDecoration: 'underline', cursor: 'hand'}}
                                                                 id={"tentativi-" + row.original.id}>{row.value}</div>
                                                            <UncontrolledTooltip placement="bottom"
                                                                                 target={"tentativi-" + row.original.id}
                                                                                 delay={0}>
                                                                <>Tentativi di consegna effettuati:</>
                                                                {orari.map(orario => <div>{orario}</div>)}
                                                            </UncontrolledTooltip></>
                                                    );
                                                },

                                            },
                                            {
                                                "Header": "firma", "accessor": "firma",
                                                filterable: false,
                                                Cell: row => {
                                                    return (
                                                        <Button
                                                            className="btn-link btn-icon btn-primary"
                                                            data-toggle="dropdown"
                                                            color={"white"}
                                                            type="button"
                                                            onClick={async () => {
                                                                try {
                                                                    const res = await GraphqlClient.ottieniFirmaBusta({id: row.original.id});
                                                                    this.setState({
                                                                        showImage: true,
                                                                        image: "data:image/jpeg;base64," + res.ottieniFirmaBusta,
                                                                        imageAlt: "Foto busta"
                                                                    });
                                                                } catch (e) {
                                                                    alert("Impossibile ottenere firma")
                                                                }
                                                            }}
                                                        >
                                                            <i className="tim-icons icon-image-02"/>
                                                        </Button>
                                                    )
                                                        ;
                                                },
                                            },
                                            {
                                                "Header": "Data firma", "accessor": "oraFirma",
                                                Cell: row => {
                                                    if (!row.value || row.value === 0) return "";
                                                    return (`${moment.unix(row.value).format("DD-MM-YYYY HH:mm")}`)
                                                },
                                            },
                                            {
                                                "Header": "Op. Firma",
                                                "accessor": "operatoreLoggatoFirma",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    if (!filter.value) return true;
                                                    return filter.value.some(f => f.value === row.operatoreLoggatoFirma);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'operatoreLoggatoFirma';
                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['operatoreLoggatoFirma'])}
                                                        optionValue={'operatoreLoggatoFirma'}
                                                        optionLabel={'operatoreLoggatoFirma'}
                                                        filterName
                                                        value={this.state.filters["operatoreLoggatoFirma"]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                },
                                            },
                                            {
                                                "Header": "trasferito", "accessor": "trasferito",
                                                Cell: row => {
                                                    if (row.value === true) return "Si";

                                                    return "";

                                                },
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    if (!filter.value) return true;
                                                    return filter.value.some(f => f.value == row.trasferito);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'trasferito';
                                                    return <ReactTableFilterSelectComponent
                                                        options={[{label: 'Si', value: true}, {
                                                            label: 'No',
                                                            value: null
                                                        }]}
                                                        optionValue={'value'}
                                                        optionLabel={'label'}
                                                        filterName
                                                        value={this.state.filters["trasferito"]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                },
                                            },
                                            {
                                                "Header": "Ufficio trasferimento", "accessor": "ufficioTrasferimento",
                                                filterMethod: (filter, row) => {
                                                    if (filter && filter.value && filter.value.length === 0) return true;
                                                    if (!filter.value) return true;
                                                    return filter.value.some(f => f.value === row.ufficioTrasferimento);
                                                },
                                                Filter: ({onChange}) => {
                                                    const filterName = 'ufficioTrasferimento';
                                                    return <ReactTableFilterSelectComponent
                                                        options={_.sortBy(this.props.data, ['ufficioTrasferimento'])}
                                                        optionValue={'ufficioTrasferimento'}
                                                        optionLabel={'ufficioTrasferimento'}
                                                        filterName
                                                        value={this.state.filters["ufficioTrasferimento"]}
                                                        onChange={(value) => this.setState({
                                                            filters: {
                                                                ...this.state.filters,
                                                                [filterName]: value
                                                            }
                                                        }, () => onChange(value))}
                                                    />
                                                },
                                            },
                                            {
                                                Header: "Azioni",
                                                Cell: this.actions,
                                                sortable: false,
                                                filterable: false,
                                                show: !this.props.readOnly,
                                            }]
                                        }
                                        defaultPageSize={10}
                                        showPaginationBottom={true}
                                        className="-striped -highlight"
                                        nextText={'Avanti'}
                                        previousText={'Indietro'}
                                        pageText={'Pagina'}
                                        ofText={'di'}
                                        rowsText={'elementi'}
                                        noDataText={'Nessun dato'}
                                        loadingText={'Caricamento in corso'}
                                        loading={false}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const data = {
        data: get(state, "raiBusta.data", []),
        loading: get(state, "raiBusta.loading", false)
    };

    if (data.data === null) data.data = [];


    return data;
};


export default connect(mapStateToProps, () => ({}))(raiBustaComponent)

export function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
}
