import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
  Row,
  Col,
  Card,
} from "reactstrap";
import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import store from "../../../store/store";
import { readedAll, created } from "../redux/trasferimenti.actions";
import GraphqlClient from "../../../client/graphql.client";

import { TextInputHook } from "../../../hooks/text_input.hook";
import { NumberInputHook } from "../../../hooks/number_input.hook";
import ClassNames from "classnames";
import Select from "react-select";

const TrasferimentiCreateComponent: FunctionComponent = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const articoli = props.articoli.ottieniArticoli;
  const magazzini = props.magazzini.ottieniMagazzini;

  const [trasferimento_articolo, setTrasferimentiArticolo] = useState();
  const [articoloSelezionato, set_articoloSelezionato] = useState({
    ID: null,
    descrizione: null,
  });

  const trasferimento_quantita = NumberInputHook();

  const [traferimento_magazzino_partenza,setTrasferimento_magazzino_partenza] = useState();
  const [magazzinoPartenzaSelezionato, setMagazzinoPartenzaSelezionato] = useState({
    ID: null,
    descrizione: null,
  });

  const [traferimento_magazzino_arrivo,setTrasferimento_magazzino_arrivo] = useState();
  const [magazzinoArrivoSelezionato, setMagazzinoArrivoSelezionato] = useState({
    ID: null,
    descrizione: null,
  });

  const clearMagazzinoPartenzaSelect = () => {
    const empty = { ID: null, descrizione: null };
    setMagazzinoPartenzaSelezionato(empty);
  };

  const clearMagazzinoArrivoSelect = () => {
    const empty = { ID: null, descrizione: null };
    setMagazzinoArrivoSelezionato(empty);
  };

  const salva = async () => {
    try {
      setLoading(true);

      const res = await GraphqlClient.inserisciTrasferimento({
        input: {
          idArticolo: articoloSelezionato.ID ? articoloSelezionato.ID : trasferimento_articolo,
          quantita: trasferimento_quantita.getValue(),
          idMagazzinoDiArrivo: magazzinoArrivoSelezionato.ID ? magazzinoArrivoSelezionato.ID : traferimento_magazzino_arrivo,
          idMagazzinoDiPartenza: magazzinoPartenzaSelezionato.ID ? magazzinoPartenzaSelezionato.ID : traferimento_magazzino_partenza,
        },
      });
      store.dispatch(created(res.inserisciTrasferimento));
      props.toggle();
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitError]);

  return (
    <Modal
      modalClassName={ClassNames("modal-black")}
      isOpen={props.show}
      toggle={props.toggle}
    >
      <Row>
        <Col xs={12} md={12}>
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={props.toggle}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Nuovo trasferimento</h6>
          </div>
        </Col>

        {/*<Col xs={12} md={6} style={{ paddingLeft: 50, paddingTop: 40 }}>*/}
        <ModalBody>
          <FormGroup>
            <Label>Articolo</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={articoloSelezionato}
              getOptionLabel={(item) => item.descrizione}
              getOptionValue={(item) => item.ID}
              onChange={(value) => {
                set_articoloSelezionato(value);
              }}
              closeMenuOnSelect={true}
              options={articoli}
              placeholder="articolo"
            />
          </FormGroup>
          <FormGroup>
            <Label for="trasferimento_quantita">Quantita</Label>
            <Input
              {...trasferimento_quantita.input}
              name="trasferimento_quantita"
              id="trasferimento_quantita"
              placeholder="Inserisci trasferimento_quantita"
            />
          </FormGroup>
          <FormGroup>
            <Label>magazzino di PARTENZA</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={magazzinoPartenzaSelezionato}
              getOptionLabel={(item) => item.descrizione}
              getOptionValue={(item) => item.ID}
              onChange={(value) => {
                setMagazzinoPartenzaSelezionato(value);
              }}
              closeMenuOnSelect={true}
              options={magazzini}
              placeholder="magazzino"
            />
            <Button
              className={"h-100 ml-3"}
              color="danger"
              size="sm"
              onClick={() => clearMagazzinoPartenzaSelect()}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>
          </FormGroup>
          <FormGroup>
            <Label>magazzino di ARRIVO</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={magazzinoArrivoSelezionato}
              getOptionLabel={(item) => item.descrizione}
              getOptionValue={(item) => item.ID}
              onChange={(value) => {
                setMagazzinoArrivoSelezionato(value);
              }}
              closeMenuOnSelect={true}
              options={magazzini}
              placeholder="magazzino"
            />
            <Button
              className={"h-100 ml-3"}
              color="danger"
              size="sm"
              onClick={() => clearMagazzinoArrivoSelect()}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>
          </FormGroup>
        </ModalBody>
        {/*</Col>*/}
      </Row>

      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={salva}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TrasferimentiCreateComponent;
