import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
  Row,
  Col,
  Card,
} from "reactstrap";
import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import store from "../../../store/store";
import { created, requestUpdate, updated } from "../redux/articoli.actions";
import GraphqlClient from "../../../client/graphql.client";
import _ from "lodash";
import { TextInputHook } from "../../../hooks/text_input.hook";
import { NumberInputHook } from "../../../hooks/number_input.hook";
import ClassNames from "classnames";
import Select from "react-select";

const ArticoliUpdateComponent: FunctionComponent = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const articolo_descrizione = TextInputHook(props.data.descrizione);
  const [articolo_barcodes, setArticolo_barcodes] = useState(() => {
    let arrayprovvisorio = [];
    props.data.barcodes.map((item, index) => {
      const newitem = { index: index, barcode: item };
      arrayprovvisorio.push(newitem);
    });
    return arrayprovvisorio
  });
  const articolo_taglia = TextInputHook(props.data.taglia);
  const articolo_note = TextInputHook(props.data.note);

  //console.log(articolo_barcodes);

  
  const addEmptyBarcode = () => {
    let newIndex = 0;
    if (articolo_barcodes.length > 0) {
      newIndex =
        _.maxBy(articolo_barcodes, function (s) {
          return s.index;
        }).index + 1;
    }    

    let newBarcode = { index: newIndex, barcode: "",};
    setArticolo_barcodes([...articolo_barcodes, newBarcode]);
  };

  const removeBarcode = (BarcodeToRemove) => {
    const barcodeList = articolo_barcodes.filter(
      (barcode) => barcode.index != BarcodeToRemove.index
    );
    setArticolo_barcodes([...barcodeList]);
  }; 
  const updateBarcode = (updatedBarcode) => {
    let newBarcode = [...articolo_barcodes];
    const index = _.findIndex(articolo_barcodes, { index: updatedBarcode.index });
    newBarcode.splice(index, 1, updatedBarcode);
    setArticolo_barcodes([...newBarcode]);
  };


  const setta = () => {
    let array=[];
    
    articolo_barcodes.map((item) => {
      const newitem = item.barcode;
      array.push(newitem);       
    })
    //console.log("array pronto per update",array)
    return array
  }

  
  const salva = async () => {
    try {
      setLoading(true);

      const res = await GraphqlClient.aggiornaArticolo({
        id: props.data.ID,
        input: {
          descrizione: articolo_descrizione.getValue(),
          barcodes: setta(),
          taglia: articolo_taglia.getValue(),
          note: articolo_note.getValue(),
        },
      });
      store.dispatch(requestUpdate(res.aggiornaArticolo));
      props.toggle();
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitError]);

  return (
    <Modal
      modalClassName={ClassNames("modal-black")}
      isOpen={true}
      toggle={props.toggle}
    >
      <Row>
        <Col xs={12} md={12}>
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={props.toggle}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Modifica Articolo</h6>
          </div>
        </Col>

        {/*<Col xs={12} md={6} style={{ paddingLeft: 50, paddingTop: 40 }}>*/}
        <ModalBody>
          <FormGroup>
            <Label for="articolo_descrizione">Descrizione Articolo</Label>
            <Input
              {...articolo_descrizione.input}
              name="articolo_descrizione"
              id="articolo_descrizione"
              placeholder="Inserisci descrizione articolo"
            />
          </FormGroup>
          {/* <FormGroup>
            <Label for="articolo_barcode">Barcode</Label>
            <Input
              {...articolo_barcodes}
              name="articolo_barcode"
              id="articolo_barcode"
              placeholder="Inserisci barcode articolo"
              value={articolo_barcodes}
              onChange={(e) => {
                //console.log(e.target.value)
                //articolo_barcodes = e.target.value;
                setArticolo_barcodes(e.target.value)
              }}
            />
          </FormGroup> */}
          {articolo_barcodes.map((barcode) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormGroup
                className="extendFlex"
                style={{ flex: 1 }}
                q
                label="Barcode"
              >
                <Input
                  name="fieldName"
                  type="text"
                  className="react-select primary"
                  classNamePrefix="react-select"
                  value={barcode.barcode}
                  onChange={(e) => {
                    barcode.barcode = e.target.value;
                    updateBarcode(barcode);
                  }}
                  placeholder="Inserire Barcode"
                />
              </FormGroup>
              <div style={{ width: "5px" }} />
              <Button
                className={"h-100 ml-3"}
                color="danger"
                size="sm"
                onClick={() => removeBarcode(barcode)}
              >
                <i className="tim-icons icon-simple-remove" />
              </Button>
            </div>
          ))}
          <button
            className="btn btn-primary w-100" onClick={addEmptyBarcode}
          >
            <i className="tim-icons icon-simple-add" />
            Aggiungi barcode
          </button>
          <FormGroup>
            <Label for="articolo_taglia">Taglia</Label>
            <Input
              {...articolo_taglia.input}
              name="articolo_taglia"
              id="articolo_taglia"
              placeholder="Inserisci taglia articolo"
            />
          </FormGroup>
          <FormGroup>
            <Label for="articolo_note">Note</Label>
            <Input
              {...articolo_note.input}
              name="articolo_note"
              id="articolo_note"
              placeholder="Inserisci note articolo"
            />
          </FormGroup>
        </ModalBody>
        {/*</Col>*/}
      </Row>

      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={salva}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ArticoliUpdateComponent;
