// @ts-nocheck
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
} from "reactstrap";
import React, { FunctionComponent, useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import store from "../../../store/store";
import { created } from "../redux/utente.actions";
import GraphqlClient from "../../../client/graphql.client";

import { TextInputHook } from "../../../hooks/text_input.hook";
import Select from "react-select";

const UtenteCreateComponent: FunctionComponent = (props: any) => {
  const utente_nome = TextInputHook("");
  const utente_cognome = TextInputHook("");
  const utente_password = TextInputHook("");
  const utente_confermaPassword = TextInputHook("");
  const utente_email = TextInputHook("");
  const [utente_tags, setUtente_tags] = useState([]);
  const [ruolo, setRuolo] = useState("");

  const [submitError, setSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);

  const magazzini = props.magazzini.ottieniMagazzini;

  const [utente_magazzino, setUtente_magazzino] = useState();
  const [magazzino_selezionato, setMagazzino_selezionato] = useState({
    ID: null,
    descrizione: null,
  });

  /* const clearMagazzinoUtenteSelect = () => {
    const empty = { ID: null, descrizione: null };
    setMagazzino_selezionato(empty);
  }; */

  const salva = async () => {
    try {
      setLoading(true);

      const res = await GraphqlClient.creaUtente({
        utente: {
          nome: utente_nome.getValue(),
          cognome: utente_cognome.getValue(),
          password: utente_password.getValue(),
          confermaPassword: utente_confermaPassword.getValue(),
          email: utente_email.getValue(),
          tags: utente_tags,
          ruolo: ruolo,
          idMagazzino: magazzino_selezionato.ID ? magazzino_selezionato.ID : utente_magazzino
        },
      });

      store.dispatch(created(res.creaUtente.utente)); // scommentare per abilitare redux: al posto di res.getDpiArticles mettere l'oggetto con i dati
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }

    props.toggle();
  };

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitError]);

  console.log("RUOLO", ruolo);
  return (
    <Modal
      modalClassName="modal-black"
      isOpen={props.show}
      toggle={props.toggle}
    >
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          <i className="tim-icons icon-simple-remove" />
        </button>
        <h6 className="title title-up">Nuovo</h6>
      </div>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            salvaLotto();
          }}
        >
          <FormGroup>
            <Label for="utente_email">Email</Label>
            <Input
              {...utente_email.input}
              name="utente_email"
              id="utente_email"
              placeholder="Inserisci utente_email"
            />
          </FormGroup>
          <FormGroup>
            <Label for="utente_password">Password</Label>
            <Input
              {...utente_password.input}
              name="utente_password"
              id="utente_password"
              type="password"
              placeholder="Inserisci utente_password"
            />
          </FormGroup>
          <FormGroup>
            <Label for="utente_confermaPassword">Conferma pwd.</Label>
            <Input
              {...utente_confermaPassword.input}
              name="utente_confermaPassword"
              id="utente_confermaPassword"
              type="password"
              placeholder="Inserisci utente_confermaPassword"
            />
          </FormGroup>
          <FormGroup>
            <Label>Ruolo</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={[ruolo]}
              closeMenuOnSelect={false}
              getOptionLabel={(item) => item}
              getOptionValue={(item) => item}
              onChange={(value) => {
                console.log("VALUE", value);
                setRuolo(value);
              }}
              closeMenuOnSelect={false}
              options={["Amministratore", "Utente"]}
              placeholder="Seleziona ruolo"
            />
          </FormGroup>
          <FormGroup>
            <Label>Tag</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={utente_tags}
              closeMenuOnSelect={false}
              isMulti
              getOptionLabel={(item) => item}
              getOptionValue={(item) => item}
              onChange={(value) => {
                setUtente_tags(value);
              }}
              closeMenuOnSelect={false}
              options={[
                "poste-teulada",
                "poste-saxa",
                "poste-mazzini",
                "poste-dear",
              ]}
              placeholder="Seleziona tags.."
            />
          </FormGroup>
          <FormGroup>
            <Label>Magazzino</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={magazzino_selezionato}
              getOptionLabel={(item) => item.descrizione}
              getOptionValue={(item) => item.ID}
              onChange={(value) => {
                setMagazzino_selezionato(value);
              }}
              closeMenuOnSelect={true}
              options={magazzini}
              placeholder="magazzino"
            />
 {/*            <Button
              className={"h-100 ml-3"}
              color="danger"
              size="sm"
              onClick={() => clearMagazzinoUtenteSelect()}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button> */}
          </FormGroup>

          {/*Il button serve solo per permettere il submit del form con il pulsante enter */}
          <button style={{ display: "none" }} type="submit" />
        </form>
        <CSSTransition
          in={submitError}
          appear={true}
          unmountOnExit
          classNames="error"
          timeout={300}
        >
          <blockquote className="blockquote blockquote-danger">
            <p className="mb-0 text-danger">Errore durante il salvataggio.</p>
          </blockquote>
        </CSSTransition>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={salva}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UtenteCreateComponent;
