import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
  Row,
  Col,
  Card,
} from "reactstrap";
import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import store from "../../../store/store";
import {
  created,
  requestUpdate,
  updated,
} from "../redux/trasferimenti.actions";
import GraphqlClient from "../../../client/graphql.client";

import { TextInputHook } from "../../../hooks/text_input.hook";
import { NumberInputHook } from "../../../hooks/number_input.hook";
import ClassNames from "classnames";
import Select from "react-select";

const TrasferimentiCreateComponent: FunctionComponent = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const articoli = props.articoli.ottieniArticoli;
  const magazzini = props.magazzini.ottieniMagazzini;

  const [trasferimento_articolo, setTrasferimento_articolo] = useState({
    ID: props.data.idArticolo ? props.data.idArticolo : null,
    descrizione: props.data.descrizioneArticolo
      ? props.data.descrizioneArticolo
      : null,
  });
  const trasferimento_quantita = NumberInputHook(props.data.quantita);

  const [
    traferimento_magazzino_partenza,
    setTrasferimento_magazzino_partenza,
  ] = useState({
    ID: props.data.idMagazzinoDiPartenza ? props.data.idMagazzinoDiPartenza : null,
    descrizione: props.data.desMagazzinoPartenza
      ? props.data.desMagazzinoPartenza
      : null,
  });

  const [
    traferimento_magazzino_arrivo,
    setTrasferimento_magazzino_arrivo,
  ] = useState({
    ID: props.data.idMagazzinoDiArrivo ? props.data.idMagazzinoDiArrivo : null,
    descrizione: props.data.desMagazzinoArrivo
      ? props.data.desMagazzinoArrivo
      : null,
  });


  const clearMagazzinoPartenzaSelect = () => {
    const empty = { ID: null, descrizione: null };
    setTrasferimento_magazzino_partenza(empty);
  };

  const clearMagazzinoArrivoSelect = () => {
    const empty = { ID: null, descrizione: null };
    setTrasferimento_magazzino_arrivo(empty);
  };
  //console.log(props)

  const salva = async () => {
    try {
      setLoading(true);

      const res = await GraphqlClient.aggiornaTrasferimento({
        id: props.data.ID,
        input: {
          idArticolo: trasferimento_articolo.ID,
          quantita: trasferimento_quantita.getValue(),
          idMagazzinoDiPartenza: traferimento_magazzino_partenza.ID,
          idMagazzinoDiArrivo: traferimento_magazzino_arrivo.ID,
        },
      });
      store.dispatch(requestUpdate(res.aggiornaTrasferimento));
      props.toggle();
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitError]);

  return (
    <Modal
      modalClassName={ClassNames("modal-black")}
      isOpen={true}
      toggle={props.toggle}
    >
      <Row>
        <Col xs={12} md={12}>
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={props.toggle}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Modifica Trasferimento</h6>
          </div>
        </Col>

        {/*<Col xs={12} md={6} style={{ paddingLeft: 50, paddingTop: 40 }}>*/}
        <ModalBody>
          <FormGroup>
            <Label>Articolo</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={trasferimento_articolo}
              getOptionLabel={(item) => item.descrizione}
              getOptionValue={(item) => item.ID}
              onChange={(value) => {
                //console.log("VALUE", value.ID);
                setTrasferimento_articolo(value);
              }}
              closeMenuOnSelect={true}
              options={articoli}
              placeholder="articolo"
            />
          </FormGroup>
          <FormGroup>
            <Label for="trasferimento_quantita">Quantita</Label>
            <Input
              {...trasferimento_quantita.input}
              name="trasferimento_quantita"
              id="trasferimento_quantita"
              placeholder="Inserisci trasferimento_quantita"
            />
          </FormGroup>
          <FormGroup>
            <Label>Magazzino di Partenza</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={traferimento_magazzino_partenza}
              getOptionLabel={(item) => item.descrizione}
              getOptionValue={(item) => item.ID}
               onChange={(value) => {
                
                setTrasferimento_magazzino_partenza(value);
              }}
              closeMenuOnSelect={true}
              options={magazzini}
              placeholder="magazzino"
            />
            <Button
              className={"h-100 ml-3"}
              color="danger"
              size="sm"
              onClick={() => clearMagazzinoPartenzaSelect()}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>
          </FormGroup>
          <FormGroup>
            <Label>Magazzino di Arrivo</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={traferimento_magazzino_arrivo}
              getOptionLabel={(item) => item.descrizione}
              getOptionValue={(item) => item.ID}
               onChange={(value) => {
                
                setTrasferimento_magazzino_arrivo(value);
              }} 
              closeMenuOnSelect={true}
              options={magazzini}
              placeholder="magazzino"
            />
            <Button
              className={"h-100 ml-3"}
              color="danger"
              size="sm"
              onClick={() => clearMagazzinoArrivoSelect()}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>
          </FormGroup>
        </ModalBody>
        {/*</Col>*/}
      </Row>

      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={salva}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TrasferimentiCreateComponent;
