import React, {useState} from 'react';
import {
    Button,
    Card,
    CardBody, CardFooter,
    CardHeader,
    Col,
    Row,
    Container,
    Form, Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";

// For auto registry purpose
import './redux/login.reducer';

import {TextInputHook} from "../../hooks/text_input.hook";
import GraphqlClient, {SetClientJwt} from "../../client/graphql.client";
import store from "../../store/store";
import {userLogged} from "./redux/login.actions";


export default function Login(props) {

    const username = TextInputHook('');
    const password = TextInputHook('');
    const [loading, setLoading] = useState(false);

    const loginFunc = async (e) => {
        if (e) e.preventDefault();

        try {
            setLoading(true);

            let res = await GraphqlClient.basicLogin({username: username.getValue(), password: password.getValue()});
            store.dispatch(userLogged(res.basicLogin)); // dispatch to redux
            SetClientJwt(res.basicLogin.accessToken);
            /* Se si vuole fare un redirect particolare dopo il login in base al ruolo modificare il codice dello switch */
            switch (res.basicLogin.ruolo) {
                case "amministratore":
                    props.history.push("/admin/raiBusta");
                    break;
                default:
                    props.history.push("/admin/raiBusta");
                    break
            }
        } catch (e) {
            console.error(e);
            return;
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className="content">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" lg="4" md="6">
                        <h1 className="logoText">Delivery express</h1>
                    </Col>
                </Row>
                <Col className="ml-auto mr-auto" lg="4" md="6">
                    <Form className="form" onSubmit={(e) => {
                        e.preventDefault();
                        loginFunc();
                    }}>
                        <button style={{display: 'none'}} type="submit"/>

                        <Card className="card-login card-white">
                            <CardHeader style={{height: "200px"}}>
                                <img
                                    alt="..."
                                    src={require("../../assets/img/card-primary.png")}
                                />
                            </CardHeader>
                            <CardBody>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="tim-icons icon-email-85"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input {...username.input} placeholder="Email" type="text"/>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="tim-icons icon-lock-circle"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Password" type="password" {...password.input} />
                                </InputGroup>
                            </CardBody>
                            <CardFooter>
                                <Button
                                    block
                                    className="mb-3"
                                    color="primary"
                                    href="#pablo"
                                    onClick={loginFunc}
                                    active={!loading}
                                    size="lg"
                                >
                                    {
                                        loading ?
                                            <span>
                             <i className="fa fa-spin fa-spinner"/> Attendi...
                    </span>
                                            : "Login"
                                    }
                                </Button>
                                <div className="pull-right">
                                    <h6>
                                        <a className="link footer-link"
                                           href="#passwordDimenticata"
                                           onClick={e => e.preventDefault()}>
                                            Password dimenticata
                                        </a>
                                    </h6>
                                </div>
                            </CardFooter>
                        </Card>
                    </Form>
                </Col>
            </Container>
        </div>
    );
}

