import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  FormText,
  Row,
  Col,
  Card,
} from "reactstrap";
import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import store from "../../../store/store";
import { created, requestUpdate, updated } from "../redux/magazzini.actions";
import GraphqlClient from "../../../client/graphql.client";

import { TextInputHook } from "../../../hooks/text_input.hook";
import { NumberInputHook } from "../../../hooks/number_input.hook";
import ClassNames from "classnames";
import Select from "react-select";

const MagazziniUpdateComponent: FunctionComponent = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const magazzino_descrizione = TextInputHook(props.data.descrizione);
  const [magazzino_padre, setMagazzino_padre] = useState({
    ID: props.data.idPadre ? props.data.idPadre : null,
    descrizione: props.data.idPadre ? props.data.descrizionePadre : null,
  });

  const padri = props.padri.ottieniMagazzini;

  const clearSelect = () => {
    const empty = { ID: null, descrizione: null };
    setMagazzino_padre(empty);
  };

  const salva = async () => {
    try {
      setLoading(true);

      const res = await GraphqlClient.aggiornaMagazzino({
        id: props.data.ID,
        descrizione: magazzino_descrizione.getValue(),
        idPadre: magazzino_padre.ID,
      });
      store.dispatch(requestUpdate(res.aggiornaMagazzino));
      props.toggle();
    } catch (e) {
      console.error(e);
      setSubmitError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submitError) {
      const timer = setTimeout(() => {
        setSubmitError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [submitError]);

  return (
    <Modal
      modalClassName={ClassNames("modal-black")}
      isOpen={true}
      toggle={props.toggle}
    >
      <Row>
        <Col xs={12} md={12}>
          <div className="modal-header justify-content-center">
            <button
              aria-hidden={true}
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={props.toggle}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
            <h6 className="title title-up">Modifica Magazzino</h6>
          </div>
        </Col>

        {/*<Col xs={12} md={6} style={{ paddingLeft: 50, paddingTop: 40 }}>*/}
        <ModalBody>
          <FormGroup>
            <Label for="magazzino_descrizione">Descrizione Magazzino</Label>
            <Input
              {...magazzino_descrizione.input}
              name="magazzino_descrizione"
              id="magazzino_descrizione"
              placeholder="Inserisci magazzino_descrizione"
            />
          </FormGroup>
          <FormGroup>
            <Label>Padre</Label>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              value={magazzino_padre}
              getOptionLabel={(item) => item.descrizione}
              getOptionValue={(item) => item.ID}
              onChange={(value) => {
                setMagazzino_padre(value);
              }}
              closeMenuOnSelect={true}
              options={padri}
              placeholder="magazzino"
            />
            <Button
              className={"h-100 ml-3"}
              color="danger"
              size="sm"
              onClick={() => clearSelect()}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>
          </FormGroup>
        </ModalBody>
        {/*</Col>*/}
      </Row>

      <ModalFooter>
        <Button
          color="danger"
          data-dismiss="modal"
          type="button"
          onClick={props.toggle}
        >
          Annulla
        </Button>
        <Button color="primary" type="button" onClick={salva}>
          {loading ? <i className="fa fa-spin fa-spinner" /> : "Salva"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MagazziniUpdateComponent;
