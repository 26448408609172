import {combineReducers} from 'redux'
/*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR IMPORT*/
import utente from "../pages/utente/redux/utente.reducer.js"
import raiBusta from "../pages/raiBusta/redux/raiBusta.reducer.js"
import raiAnagraficaPoste from "../pages/raiAnagraficaPoste/redux/raiAnagraficaPoste.reducer.js"
import LoginReducer from '../pages/Login/redux/login.reducer'
import articoli from '../pages/articoli/redux/articoli.reducer'
import magazzini from '../pages/magazzini/redux/magazzini.reducer'
import consegne from '../pages/consegne/redux/consegne.reducer'
import trasferimenti from '../pages/trasferimenti/redux/trasferimenti.reducer'
import destinatari from '../pages/destinatari/redux/destinatari.reducer'

export default combineReducers({
  // Reducers here
  login: LoginReducer,
  /*DO NOT EDIT OR DELETE THIS LINE: YEOMAN ANCHOR REDUCER*/
utente,

raiBusta,

raiAnagraficaPoste,

articoli,
magazzini,
consegne,
trasferimenti,
destinatari,
})
